<template>
	<div>
		<nav-box></nav-box>

		<div id="app" class="main">
			<div class="main-page" style="text-align: center;">
				<div v-if="qrcode" style="padding: 60px;">
					<div style="font-size: 20px;">订单支付</div>
					<div style="font-size: 16px;margin-top: 20px;">微信扫一扫付款（元）</div>
					<div style="font-size:40px;font-weight: 700; margin-top: 15px;">{{order.ticOrderMoney/100}}元</div>
					
					<div style="margin-top: 30px;">
						 <vue-qr :text="qrcode" :size="300" qid="paymentid" backgroundColor="#f4f4f4"></vue-qr>
					</div>
				</div>
				
			</div>
			
		</div>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import VueQr from 'vue-qr'
	import {mapState ,mapMutations} from 'vuex'
	import { getTickerOrder,getWxpay} from '@/api/public.js'
	import {formatTraveDate,ymd_hhmmss,ymd} from '@/utils/format.js'
	export default {
		components: {
			VueQr,
		},
		computed: {
			...mapState({
				hasLogin: state => state.user.hasLogin,
				user: state => state.user.user,
			}),
		},
		data() {
			return {
				orderId:'',
				order:null,
				qrcode:''
			}
		},
		created() {
			if(this.$route.query.orderId){
				this.orderId =this.$route.query.orderId;
				this.getData();
			}
		},
		beforeDestroy() {
			this.clearTimer();
		},
		methods: {
			createTimer(){
				this.timer = setInterval(()=>{
					this.checkData();
				},1000)
			},
			clearTimer(){
				if(this.timer){
					clearInterval(this.timer);
					this.timer =null;
				}
			},
			showError(msg) {
				this.$message({
					showClose: true,
					message: msg,
					type: 'error',
					offset: 100
				});
			},
			showSuccess(msg) {
				this.$message({
					showClose: true,
					message: msg,
					type: 'success',
					offset: 100
				});
			},
			checkData(){
				let data={ticOrderId:this.orderId ,clientId:'weixinManagement'}
				getTickerOrder(data).then(res=>{
					if(res.data.code==200){
						let order =res.data.data;
						if(order.ticOrderStatus==201){
							this.showSuccess('支付成功');
							this.clearTimer();
							setTimeout(()=>{
								this.$router.replace({
									path:'/peopleInfo'
								},1500)
							})
						}
						
					}
				})
			},
			getData(){
				let data={ticOrderId:this.orderId ,clientId:'weixinManagement'}
				getTickerOrder(data).then(res=>{
					if(res.data.code==200){
						this.order =res.data.data;
						if(this.order.ticOrderStatus==101){
							this.getPayment()
						}
						if(this.order.ticOrderStatus ==201){
							this.showError('该订单已支付');
							setTimeout(()=>{
								this.$router.replace({
									path:'/peopleInfo'
								},1500)
							})
						}
						
					}
				})
			},
			getPayment(){
				getWxpay(this.order.ticOrderId).then(res=>{
					if(res.data.code==200){
						this.qrcode =res.data.data.codeUrl;
						this.createTimer();
					}
				})
			},
			
		},
	}
</script>

<style lang="less">
	.main-page{
		margin-top: 100px;
		width: 100%;
		height: auto;
		position: relative;
	}
</style>
