<template>
	<div class="three">
		<nav-box></nav-box>
		<div>
			<div id="app" class="main">
				<div class="site_banner">
					<img src="../../assets/img/xishan-bg4.jpg">
				</div>

				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="20" :xs="24">
						<Title title="门票预定"></Title>
						
						<div class="food_dcont" v-if="ticketData">
						    <div class="pub_cont">
						        <div class="pub_le">
						            <div class="souvenir_p01">
						                <h1 class="fnt_30">{{ticketData.ticName}}</h1>
						                <p class="pub_p24">开放时间：西山景区开放时间：9:00-23:00，西山景区检票时间：9:00-21:00；请合理安排您的行程。</p>
						                <p class="pub_p24">门票说明：{{ticketData.ticDesc}}</p>
						                <div class="warning_p fnt_18">包含景点: {{ticketData.scenicSpotsNames}}</div>
						            </div>
										
						            <div class="souvenir_p02 ticket_type">
						                <div class="group_type" style="display: flex;flex-direction: column">
						                    <h2 class="fnt_18">门票预定</h2>
										
						                    <div class="calendar_numbox">
						                        <div class="pub_calendar">
						                            <div class="calendar_box">
						                                <div class="calendar date calendar01">
						                                    <span><img src="../../assets/image/week.png">游玩日期   </span>
						                                    <input onchange="selectDate(this)" :value="date" class="test-item" type="date" id="test-0" lay-key="1">
						                                </div>
						                            </div>
						                        </div>
										
						                    </div>
						                    <div v-if="appointment">
						                     <!--   <h2 class="fnt_18">选择批次</h2>
										
						                        <el-select v-if="batchList.length>0" v-model="batchIndex" placeholder="请选择" style="width:230px;"  @change="batchStock()">
						                            <el-option
						                                    v-for="(item,index) in batchList"
						                                    :key="index"
						                                    :label="item.timeSlot"
						                                    :value="index">
						                            </el-option>
						                        </el-select>
						                        <div v-else="batchList" style="color: red">今日没有批次可以预约</div>
										 -->
						                        <div class="pub_form" style="margin-top: 10px">
						                            <h3 class="fnt_18">预订人信息</h3>
						                            <div class="shopping_form">
										
						                                <ul>
						                                    <li class="li01">
						                                        <div class="form_w">
						                                            <div class="form_input">
						                                                <input placeholder="预订人姓名" type="text" v-model="receiverName" maxlength="32">
						                                            </div>
						                                        </div>
						                                    </li>
						                                    <li class="li02 ">
						                                        <div class="form_w">
						                                            <div class="form_input">
						                                                <input placeholder="预订人手机号码（便于接收通知）" v-model="receiverPhone"
						                                                       type="text">
						                                            </div>
						                                        </div>
						                                    </li>
															
															<li class="li02 phoneSelect homeSelect">
																<div class="form_w">
																	<div class=" clearfix">
																		<dl class="select_c">
																			<dt><i>证件类型</i>
																				<p class="certType">
																					身份证</p>
																			</dt>
																		</dl>
																	</div>
																</div>
															</li>
															<li class="li01">
																<div class="form_w">
																	<div class="form_input"><input placeholder="证件号码" type="text"
																			class="certNo"></div>
																</div>
															</li>
										
						                                </ul>
						                            </div>
						                        </div>
										
						                    </div>
										
										
										
						                    <div class="type_iteam">
						                        <ul id="ticket-item-list">
													<li class="ticketItem">
														<h3 class="fnt_16">{{ticketData.ticName}}<span class="stock" style="color: red"></span></h3>
														<p class="fnt_24">¥{{ticketData.ticRetailPrice/100}}</p>
														<div class="addjian">
															<span>预订数量</span>
															<div id="tab">
																<input class="addbtn min" name="" type="button" value="-" @click="minus">
																<input class="text_box" name="" type="text" min="1" v-model="ticNum" />
																<input class="addbtn add" name="" type="button" value="+"  @click="plus"/>
															</div>
														</div>
													</li>
						                        </ul>
						                    </div>
										
						                </div>
										
						            </div>
						            <div class="ticket_edit_block">
						                <div class="cart_edit">
						                    <div class="cart_edit_01" style="margin-top: 20px;">
						                        <h1 class="fnt_24">预订须知</h1>
												{{ticketData.firstBuyNoticeList[0]['noticeContent']}}
						                        <div class="edit">
						                            <p>为坚持做好疫情防控工作，保障您和他人的人身财产安全，游览前，请您仔细阅读并遵守以下须知，共建文明有序的旅游环境，有疑问请及时咨询客服或拨打电话3209622</p>
						                            <p style="font-weight: 400;">【电子票使用须知】：</p>
						                            <p style="font-weight: 400;">1、西山景区开放时间：9:00-23:00：西山景区检票时间：9:00-21:00；请合理安排您的行程。</p>
						                            <p style="font-weight: 400;">2、检票地点：游客服务中心检票口</p>
						                            <p style="font-weight: 400;"><strong>3、门票当日一次进出有效，刷码注销，不能重复使用。</strong></p>
						                            <p style="font-weight: 400;">4、如游览当天无法打开二维码短信，请联系现场工作人员或者拨打3209622进行协助解决。</p>
						                            <p style="font-weight: 400;"><strong>5、退改规则：如需退票，请在门票未使用的情况下于游玩日期当天24点前发起退票申请，逾期不接受退票。</strong></p>
						                            <p>&nbsp;</p>
						                            <p>【预订须知】：</p>
						                            <p>1、景区内各开放场所已按相关防疫要求做好全面消毒工作，景区工作人员已完成14天行踪轨迹排摸，无疫区和人员接触史，无发热、咳嗽等身体不适症状。</p>
						                            <p>2、请您凭本人有效身份证件，通过关注&ldquo;西山风景区&rdquo;官方微信号或其他官方授权渠道完成预定。</p>
						                            <p>3、请您在到达景区前，提前通过&ldquo;健康宝&rdquo;小程序申领&ldquo;健康码&rdquo;，通过三大运营商行程轨迹查询结果，并请携带好本人身份证。景区暂不接待中高风险地区及入境日期未满14天的旅客。</p>
						                            <p>4、建议您自驾前往西山景区，以免因乘坐公共交通工具等原因而引起&ldquo;健康码&rdquo;的异常，影响您的游玩。</p>
						                            <p>5、游客游览过程中，请务必做好个人防护工作，须全程佩戴口罩。在检票口、餐厅等人群易聚集场所须与他人保持1米以上距离，严禁扎堆，并配合景区工作人员现场引领和管理。</p>
						                            <p>6、游客对本人所提供信息的真实性负责，在游览途中一旦出现发热、咳嗽等身体不适情况，请第一时间告知景区工作人员。</p>
						                            <p>7、根据疫情防控需要，景区管控措施、开放区域等可能会有调整，并可能根据景区承载量实施限流措施，请关注景区官方公告，并配合工作人员的安排。</p>
						                            <p>8、请广大游客朋友认真阅读《西山风景区游览须知》，遵守相关的法律法规，有序游览。</p>
						                            <p style="font-weight: 400;">&nbsp;</p>
						                            <p><strong>注意事项:</strong></p>
						                            <p><strong>1</strong><strong>、以上优惠均需出示本人真实合法有效身份证件；</strong></p>
						                            <p><strong>2</strong><strong>、持有旅行社经理资格证（国家旅游局颁发）、导游证（IC卡）、领队证、随团司机驾驶A1证的，仅限旅行社随团工作人员免票（凭本人有效证件及景区团购单随团进入景区）。</strong></p>
						                            <p><strong>3</strong><strong>、凭记者证免票需在游客服务中心咨询台办理免票登记手续方可进入景区。</strong></p>
						                            <p><strong><span style="color: #e53333;">4、</span><span style="font-family: SimSun; color: #e53333;">所有免票和优惠人员均须携带相关有效证件，经现场核验有效证件并出示</span><span style="color: #e53333;">&ldquo;健康码&rdquo;后，方可享受相应政策。</span></strong></p>
						                            
						                            <p>保留权利</p>
						                            <ul>
						                                <li>为确保公众场合的秩序及各位游客的安全，我们可能在度假区公共区域内摄像、摄影或录音。进入度假区即视为您同意被摄像、摄影或录音，并同意我们将包含您肖像或声音的影像、录音用于度假区行政、运营目的或其他直接相关的目的，或用于满足法律、安全、保安要求的目的及其它合法目的。</li>
						                                <li>出于度假区安全、秩序稳定的考虑，或者在其他必要的情况下：</li>
						                            </ul>
						                            <p>1）我们可能拒绝个别游客入园，或要求个别游客离园；</p>
						                            <p>2）我们可能基于以下原因更改度假区及景点、设施的开放时间，暂停开放或控制入内人数，更改、暂停或取消任何游览设施、项目：度假区承载量、恶劣天气、政府管制或其他不可抗力事件；保障安全、治安或秩序；修缮、保养、施工；或其他我们认为必要的情况。</p>
						                            <ul>
						                                <li>本游客须知可能会不时进行修订。如法律另有强制性规定，则以相应法律规定为准。</li>
						                            </ul>
						                      
										
						                        </div>
						                    </div>
										
						                </div>
										
										
										
						            </div>
										
	
										
						        </div>
						        <div class="pub_re" id="feiyong">
						            <div class="pub_detailed">
						                <h1 class="fnt_18">费用明细</h1>
						                <div class="cost">
						                    <div class="cost_time">
						                        <div class="detailed_ul">
						                            <ul>
						                                <li>
						                                    <p>游玩日期</p>
						                                    <span id="cost_time">{{date}}</span>
						                                </li>
						                            </ul>
						                        </div>
						                    </div>
						                    <div class="cost_text cost_01">
						                        <div class="cost_insert cost_p01">
						                            <div class="detailed_ul">
						                                <ul>
						                                    <li>
						                                        <p>门票</p>
						                                        <span class="totalPrice">¥{{ticketData.ticRetailPrice/100}} x {{ticNum}}</span>
						                                    </li>
						                                </ul>
						                            </div>
						                        </div>
						                    </div>
						                </div>
						                <div class="cost_total fnt_18">
						                    <p>总价</p>
						                    <span class="totalPrice">¥{{totalPrice/100}}</span>
						                </div>
						                <div class="cost_btn">
						                    <a class="cost_btn02" href="javascript:void(0);" @click="addOrder()">立即预定</a>
						                </div>
						            </div>
						        </div>
						    </div>
										
						</div>
					</el-col>
				</el-row>

			</div>
		</div>
		<footerBox />
	</div>
</template>

<script>
	import{getTicketDetail ,getConsume ,getTraveler ,buyTicket} from '@/api/public.js'
	import {
		ymd,
		getWeek,
		getStamp
	} from '@/utils/format.js'
	import {mapState ,mapMutations} from 'vuex'
	import NavBox from '@/components/navBox.vue'
	export default {
		name: 'three',
		components: {
			NavBox
		},
		data() {
			return {
				batchIndex:0,
				timeSlot:"",
				appointment:1,
				batch:"1",
				batchList: [],
				originBatchList: [],
				commentTotal: 0,
				commentList: [],
				receiverName:"",
				receiverPhone:"",
				
				id:0,
				date: ymd(),
				ticketData: null,
				ticNum: 1,
				travelInfoList: [],
				checkIds: [],
				peopleIndex: 0,
				realConfig: 0,
				consume: null, //余额
			}
		},
		computed: {
			...mapState({
				hasLogin: state => state.user.hasLogin,
				user: state => state.user.user,
			}),
			dateWeek() {
				return this.date ? getWeek(this.date) : ''
			},
			totalPrice() {
				let totalPrice = 0;
				if (this.ticketData) {
					let ticPrice = this.ticketData.ticRetailPrice;
					totalPrice = ticPrice * this.ticNum;
				}
				return totalPrice
			},
			travelNum() {
				return this.realConfig == 3 || this.realConfig == 5 ? 1 : this.ticNum;
			}
		},
		created() {
			this.id =this.$route.query.id;
			this.getData();
		},
		methods: {
			getData(){
				this.getTicket();
				this.getConsume();
				this.getTraveler();
			},
			getTicket() {
				getTicketDetail({id: this.id,date: this.date}).then(res => {
					if (res.data.code == 200) {
						this.ticketData = res.data.data
					}
				})
			},
			getConsume() {
				getConsume({wxUserId: this.user.userId}).then(res => {
					if (res.data.code == 200) {
						this.consume = res.data.data.items;
					}
				})
			},
			getTraveler() {
				getTraveler({limit: 20,page: 1,traUserId: this.user.userId}).then(res => {
					let realConfig = this.realConfig || res.data.data.realConfig;
					this.realConfig = realConfig;
				})
			},
			addOrder(){
				if(!this.hasLogin){
					return this.$router.push({name:'login'});
				}
				// this.$router.push({name:'ticketReserve' ,query:{id:this.id}});
				this.submit()
			},
			submit() {
				let ticketDTOS = [];
				ticketDTOS[0] = {
					ticId: this.ticketData.ticId, //门票Id
					ticBuyNum: this.ticNum, //购买数量
					ticOriginalPrice: this.ticketData.ticOriginalPrice, //宣传价格(单位：分)
					ticVariety: this.ticketData.ticVariety, //门票种类（1.按数量划分子订单，2.按门票划分子订单, 3.开闸一次进多个人）
					ticName: this.ticketData.ticName, //门票名称
					ticNumber: this.ticketData.ticNumber, //门票编码
					ticPeopleNum: this.ticketData.ticPeopleNum, //允许人数
					ticRetailPrice: this.ticketData.ticRetailPrice, //实际价格(单位：分)
					ticTotal: this.ticketData.ticTotal, //门票数量
					ticValidEnd: this.ticketData.ticValidEnd, //结束时间
					ticValidStart: this.ticketData.ticValidStart, //开始时间
					ticValidType: this.ticketData.ticValidType, //门票有效类型(1:单日,2:多日,3:区间)
					ticValidTime: this.ticketData.ticValidTime, //游玩天数
					ticViewId: this.ticketData.ticViewId, //景点Id集合
				}
			
				let travelInfoList = this.travelInfoList.length > 0 && this.travelInfoList[0]['traName'] ? this
					.travelInfoList : [];
				if (travelInfoList.length > this.travelNum) {
					//travelInfoList =travelInfoList.splice(1)
				}
				let data = {
					ordUserId: this.user.userId,
			
					ordActualPrice: this.totalPrice,
					//支付金额
					ordBuyWay: 4,
					//购买渠道 4：微信
					ordCouponId: -1,
					//优惠券ID 无 -1
					ordCouponPrice: 0,
					//优惠金额(单位：分)
					ordMemberPrice: 0,
					ordOrderPrice: this.totalPrice,
					//订单费用(单位：分)
					ordOrderStatus: 101,
					//订单状态 101：未付款
					ordPayWay: this.totalPrice>0 ?2 : 9,
					//支付方式 2：微信 9：余额
					ordReserveDate: getStamp(this.date),
					//预定时间
					ordScenicId: this.ticketData.ticScenicId,
					//景区id
					ticViewId: this.ticketData.ticViewId,
					//景点id
					ordSellerId: -1,
					//售票员ID（默认-1）
					ordSellerName: '官网',
					//售票员名称(小程序)
					subOrderStatus: 101,
					//子订单状态 101：未付款
					clientId: 'weixinManagement',
					//客服端ID
					realConfig: this.realConfig,
					isLocalTourist: false,
					ticketDTOS: ticketDTOS,
					// 门票集合
					travelInfoList: travelInfoList[0] && JSON.stringify(travelInfoList[0]) != '{}' ? travelInfoList :
					[],
					//游客信息集合
					ordTourist: travelInfoList.length === 0 ? '' : travelInfoList[0].traName,
					// 联系人姓名
					ordMobile: travelInfoList.length === 0 ? '' : travelInfoList[0].traMobile,
					// 联系人手机
					ordUcardId: travelInfoList.length === 0 ? '' : travelInfoList[0].traUcardId // 联系人身份证
				}
			
				buyTicket(data).then( (res) => {
					if (res.data.code == 200) {
						let orderId = res.data.data;
						this.$router.push({
							path:'/peopleInfo?orderId=' + orderId
						})
					} else {
						this.showError(res.data.msg ? res.data.msg : '提交失败')
					}
				})
			},
			minus(){
				if(this.ticNum==1){
					return false;
				}else{
					this.ticNum--;
				}
			},
			plus(){
				this.ticNum ++;
			},
			showError(msg) {
				this.$message({
					showClose: true,
					message: msg,
					type: 'error',
					offset: 100
				});
			},
		}
	}
</script>
<style lang="less" scoped>
	.calendar_numbox .calendar{
		width: 270px;
	}
</style>
