<template>
	<div id="" class="hotbg bg2">
		<nav-box></nav-box>
		<div id="" class="topimg">
			<img src="../../assets/img/topImg.png">
		</div>
		<div id="" class="foodbox">
			<img class="bgimg" src="../../assets/image/f9.png">
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<h2 class="H2title">
						<p>XISHAN DELICIOUS</p>
						<span id="">
							西山美食
						</span>
						<div id="" class="guidtitle">
							<a href="/">首页></a><a href="/">出行西山></a><a>西山美食</a>
						</div>
					</h2>
					<h3 class="suntitleOne">西山特色菜</h3>
					<el-row :gutter="30" type="flex" class="row-bg" justify="center" v-if="items1&&items1.length>0">
						<el-col :span="12" :xs="24">
							<div id="" class="imgnew" @click="goDetail(items1[0])">
								<div id="" class="inbox media-img">
									<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+items1[0].coverPic">
								</div>
								<div>
									<h3 class="eltwo">{{items1[0].title}}</h3>
									<p class="">
										{{items1[0].contentValidity}}
									</p>
									<div id="" class="show-more">
										<span id="" class="spanfonst">
											了解更多
										</span>
									</div>
								</div>

							</div>
						</el-col>
						<el-col :span="12" :xs="24">
							<el-row :gutter="30" type="flex" class="row-bg hotContent" justify="flex-start">
								<el-col :span="12" :xs="24" v-for="(i,index) in items1"	v-if="index>0 && index<=items1_total">
									<li style="width: 100%;" @click="goDetail(i)">
										<div id="" class="hinbox">
											<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+i.coverPic">
										</div>
										<h6 class="eltwo">{{i.title}}</h6>
										<div id="" class="show-more">
											<span id="" class="spanfonst">
												了解更多
											</span>
										</div>
									</li>
								</el-col>
								<el-col :span="12" :xs="24" v-if="!items1_more && items1.length>items1_total+1">
									<div id="" class="haveMore" @click="haveMore(1)">
										<div id="" >
											<img src="../../assets/image/fmore.png">
										</div>
										<h3>剩余{{items1.length-items1_total-1}}项</h3>
										<p>展开全部 <i class="el-icon-caret-bottom"></i> </p>
									</div>

								</el-col>

							</el-row>
						</el-col>
					</el-row>
					<ul class="hotContent flex" v-if="items1_more">
						<li v-for="(i,index) in items1" v-if="index>items1_total" @click="goDetail(i)">
							<div id="" class="hinbox">
								<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+i.coverPic">

							</div>
							<h6 class="eltwo">{{i.title}}</h6>
							<div id="" class="show-more">
								<span id="" class="spanfonst">
									了解更多
								</span>
							</div>
						</li>
					</ul>

				</el-col>
			</el-row>
		</div>
		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<h3 class="suntitleOne" style="margin-top: 100px;">西山特色小吃</h3>
				<el-row :gutter="30" type="flex" class="row-bg" justify="center" v-if="items2&&items2.length>0">
					<el-col :span="12" :xs="24">
						<div id="" class="imgnew" @click="goDetail(items2[0])">
							<div id="" class="inbox media-img">
								<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+items2[0].coverPic">
							</div>
							<div>
								<h3 class="eltwo">{{items2[0].title}}</h3>
								<p class="">
									{{items2[0].contentValidity}}
								</p>
								<div id="" class="show-more">
									<span id="" class="spanfonst">
										了解更多
									</span>
								</div>
							</div>

						</div>
					</el-col>
					<el-col :span="12" :xs="24">
						<el-row :gutter="30" type="flex" class="row-bg hotContent" justify="flex-start">
							<el-col :span="12" :xs="24" v-for="(i,index) in items2" v-if="index>0 && index<=items2_total">
								<li style="width: 100%;" @click="goDetail(i)">
									<div id="" class="hinbox">
										<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+i.coverPic">
									</div>
									<h6 class="eltwo">{{i.title}}</h6>
									<div id="" class="show-more">
										<span id="" class="spanfonst">
											了解更多
										</span>
									</div>
								</li>

							</el-col>
							<el-col :span="12" :xs="24" v-if="!items2_more && items2.length>items2_total">
								<div id="" class="haveMore" @click="haveMore(2)">
									<div id="">
										<img src="../../assets/image/fmore.png">
									</div>
									<h3>剩余{{items2.length-items2_total-1}}项</h3>
									<p>展开全部 <i class="el-icon-caret-bottom"></i> </p>
								</div>

							</el-col>

						</el-row>
					</el-col>
				</el-row>
				<ul class="hotContent flex" v-if="items2_more">
					<li v-for="(i,index) in items2" v-if="index>items2_total" @click="goDetail(i)">
						<div id="" class="hinbox">
							<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+i.coverPic">

						</div>
						<h6 class="eltwo">{{i.title}}</h6>
						<div id="" class="show-more">
							<span id="" class="spanfonst">
								了解更多
							</span>
						</div>
					</li>
				</ul>

			</el-col>
		</el-row>
		<el-image-viewer v-if="showViewer" :on-close="()=>{showViewer=false}" :url-list="imgList" />
		<div id="" class="padding_bottom"></div>
		<footerBox />

	</div>
</template>

<script>
	import{getArticleList , getArticleDetail} from '@/api/content.js'
	export default {

		components: {
			'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
		},
		data() {
			return {
				list: [{
						groShowUrl: require('../../assets/image/f8.png'),
						groName: '武昌鱼'
					},
					{
						groShowUrl: require('../../assets/image/f1.png'),
						groName: '沙窝豆丝'
					},
					{
						groShowUrl: require('../../assets/image/f2.png'),
						groName: '鄂州鱼丸子'
					},
					{
						groShowUrl: require('../../assets/image/f3.png'),
						groName: '太和千张'
					},
					{
						groShowUrl: require('../../assets/image/f3.png'),
						groName: '太和千张'
					}
				],
				list2: [{
						groShowUrl: require('../../assets/image/f4.png'),
						groName: '小天池蒸南瓜'
					},
					{
						groShowUrl: require('../../assets/image/f5.png'),
						groName: '鄂城菜盒子'
					},
					{
						groShowUrl: require('../../assets/image/f6.png'),
						groName: '周屴板鸭'
					},
					{
						groShowUrl: require('../../assets/image/f7.png'),
						groName: '水煮馓子'
					},
					{
						groShowUrl: require('../../assets/image/f7.png'),
						groName: '水煮馓子'
					}
				],
				
				showMore: false,
				showViewer: false,
				imgList: [],


				items1:[],
				items2:[],
				items1_total: 3,
				items2_total: 3,
				items1_more:false,
				items2_more:false
			}
		},
		created() {
			this.getData();
		},
		methods: {
			getData() {
				let data={
					categoryId:18
				}
				getArticleList(data).then(res=>{
					if(res.data && res.data.data && res.data.data.records){
						this.items1 = res.data.data.records
					}
				})
				
				data={
					categoryId:19
				}
				getArticleList(data).then(res=>{
					if(res.data && res.data.data && res.data.data.records){
						this.items2 = res.data.data.records
					}
				})
			},
			haveMore(type) {
				if(type==1){
					this.items1_more = true;
					this.items1_total=4;
				}
				if(type==2){
					this.items2_more = true;
					this.items2_total=4;
				}
				// this.listTotal = 4
			},
			previewImg(item) {
				let data = {
					limit: 50,
					page: 1,
					picGroId: item.groId
				}
				this.$http.getPictures(data).then(res => {
					let imgs = [];
					if (res.data && res.data.data && res.data.data.items.length > 0) {
						res.data.data.items.forEach(item => {
							imgs.push(item.picUrl)
						})
					}
					this.imgList = imgs;
					this.showViewer = true;
				})
			},
			goDetail(item){
				this.$router.push({
					path:'/newDetail',
					query:{id:item.id}
				})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.suntitleOne {

		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 50px;
		margin-bottom: 55px;
	}

	.foodbox {
		position: relative;

		.bgimg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.8;
		}
	}

	.bg2 {
		background-color: #fbf9f7;
	}

	.haveMore {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		height: 300px;
		cursor: pointer;

		div {
			width: 47px;
			height: 56px;

			img {
				width: 100%;
			}

		}

		h3 {
			font-size: 26px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		p {
			font-size: 15px;
			padding-top: 20px;

			i {
				padding: 0 5px;
			}
		}

	}

	.imgnew {
		background-color: #FFFFFF;
		width: 100%;
		// box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.2);
		cursor: pointer;

		.inbox {
			width: 100%;
			padding-bottom: 75%;
			overflow: hidden;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
			}
		}


		h3 {
			padding: 15px;
			font-size: 26px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;

		}

		p {
			height: 103px;
			overflow: hidden;
			padding: 10px 20px;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #575757;
			line-height: 28px;
		}
	}

	.haveMore {
		width: 100%;

	}



	.topimg {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;

		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;

			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}

		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 546px;
			height: 2px;
			background-color: #d8c6a3;

		}

		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 546px;
			height: 2px;
			background-color: #d8c6a3;

		}

		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../../assets/img/fontbg.png) no-repeat bottom;

		}
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.hotContent {
		width: 100%;
		justify-content: flex-start;
		padding-bottom: 40px;
		flex-wrap: wrap;

		li {
			margin: 0 1%;
			// background-color: #FFFFFF;
			width: 23%;
			margin-bottom: 26px;
			cursor: pointer;

			&:hover {
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			}

			.hinbox {
				margin-bottom: 5px;
				padding-bottom: 75%;
				position: relative;

				img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}



			h6 {

				padding: 15px;
				line-height: 36px;
				font-size: 24px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #000000;

			}

			p {
				padding: 0 20px 0px;
				height: 75px;
				line-height: 25px;
				font-size: 15px;
				font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
				font-weight: 400;
				color: #747474;

			}
		}
	}

	.show-more {

		padding: 15px;

		span {
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #989898;
			line-height: 37px;
			letter-spacing: 0.8px;
			padding-bottom: 9px;
			border-bottom: solid 2px #989898;

		}

	}
	// .media-img{
	// 	height: 0 !important;
	// }
</style>
