// 时间格式化 
const ymd_hms = time => {
  let date
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}
const ymd_hhmmss = (time, type) => {
  type = type || '-'
  let date
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join(type) + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const ymd = (time, type) => {
  type = type || '-'
  let date
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return [year, month, day].map(formatNumber).join(type)
}
const afterSixMonth = () => {
  let date = new Date()
  let month = undefined
  let year = undefined
  if ( date.getMonth() + 7 > 12) {
    year = date.getFullYear() + 1
    month = date.getMonth() - 5
  } else {
    year = date.getFullYear()
    month = date.getMonth() + 7
  }
  const day = date.getDate()

  return [year, month, day].map(formatNumber).join('-') 
}

const afterDay = (dayNum) => {
  let date = new Date()
  date.setDate(date.getDate() + dayNum)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber).join('-')
}

const tomorrow = (time) => {
  let date
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  date.setDate(date.getDate() + 1)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return [year, month, day].map(formatNumber).join('-')
}

const afterTomorrow = time => {
  let date
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  date.setDate(date.getDate() + 2)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return [year, month, day].map(formatNumber).join('-')
}
const formatTraveDate = (type, date) => {
  switch (type) {
    case 1:
    case 2:
      return ymd(date, '.')
    case 3:
    case 4:
    case 5:
      return ymd_hhmmss(date, '.')
  }
}

const getStamp = time => {
  let date
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  return date.getTime()
}

const getWeek = time => {
  let date
  let week
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }
  const weekNum = date.getDay()
  switch (weekNum) {
    case 0:
      week = '周日'
      break;
    case 1:
      week = '周一'
      break;
    case 2:
      week = '周二'
      break;
    case 3:
      week = '周三'
      break;
    case 4:
      week = '周四'
      break;
    case 5:
      week = '周五'
      break;
    case 6:
      week = '周六'
      break;
  }
  return week
}


const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

// 商品价格格式化
const priceFormat = price => {
  let arr = (price / 100).toFixed(2).split('.')
  return [arr[0], '.' + arr[1]]
}

module.exports = {
  ymd_hms,
  ymd_hhmmss,
  ymd,
  afterSixMonth,
  afterDay,
  tomorrow,
  afterTomorrow,
  priceFormat,
  getStamp,
  getWeek,
  formatTraveDate
}