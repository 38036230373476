<template>
	<div id="" class="addressbox">
		<div id="" class="addAddress">
			<span id="" @click="addAddress">
				<i class="el-icon-plus"></i> 添加游客信息
			</span>
		</div>
		<div id="" class="addressUL">
			<div id="" class="addressli addressTitle flex">
				<p>姓名</p>
				<p>身份证号</p>
				<p>手机号</p>
				<p>操作</p>
			</div>
			<div id="" class="addressli  flex addresslicon" v-for="(item,index) in items" :key="index">
				<p class="pfont">{{item.traName}}</p>
				<p class="pfont">{{item.traIcCard}}</p>
				<p class="pfont">{{item.traPhone}}</p>
				<p> <button class="pfont" @click="remove(index)">删除</button> </p>
			</div>

		</div>
		<div id="" class="dialogBox">
			<el-dialog :visible.sync="dialogVisible" width="30%" >
				<h2>添加游客信息</h2>
				<el-form  :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="游客姓名" prop="traName">
						<el-input v-model="ruleForm.traName" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="traMobile">
						<el-input type="number" v-model="ruleForm.traMobile" placeholder="请输入手机号"></el-input>
					</el-form-item>
					<el-form-item label="身份证号" prop="traUcardId">
						<el-input type="text" v-model="ruleForm.traUcardId" placeholder="请输入身份证号"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer" style="display:flex;">
					<el-button  class="cancleBtn"  @click="dialogVisible = false">取 消</el-button>
					<el-button  class="commitBtn" @click="submitForm(ruleForm)">提交</el-button>
				</span>
			</el-dialog>
		</div>

	</div>
</template>

<script>
	import chinaCityData from "@/utils/json/chinaCity.json";
	export default {
		props: {
			items: {
				type: Array,
				default: function(){
					return []
				}
			},
		},
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					traName:'',
					traMobile:'',
					traUcardId:''
				},
				rules: {
					traName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					traMobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					traUcardId: [{
						required: true,
						message: '请输入身份证号',
						trigger: 'blur'
					}],
				},
				provinceData: [],
				addressProps: {
					label: "name",
					value: "name",
					children: "cityList",
				},

			}
		},
		created() {
			this.provinceData = chinaCityData;
		},
		methods: {
			handleChange(value) {
				console.log(value);
				if (value && value.length > 0) {
					this.ruleForm.province = value[0]
					this.ruleForm.city = value[1]
					this.ruleForm.region = value[2]
					this.queryTeacher()
				}
			},
			submitForm() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.$emit('save' ,this.ruleForm);
						this.resetForm();
						this.dialogVisible = false
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			addAddress() {
				this.dialogVisible = true
			},
			resetForm() {
				this.$refs['ruleForm'].resetFields();
			},
			remove(index){
				this.$emit('remove' ,index);
			}
		}
	}
</script>

<style lang="less">
	.flex {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.flex_column {
		flex-direction: column;
	}

	.justify_start {
		justify-content: flex-start;
	}

	.justify_between {
		justify-content: space-between;
	}

	.align_end {
		align-items: flex-end;
	}

	.align_start {
		align-items: flex-start;
	}

	.addressbox {
		padding: 30px 0;
	}

	.addAddress {
		text-align: right;

		span {
			text-align: center;
			display: inline-block;
			background: #BE9775;
			width: 184px;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			// font-weight: bold;
			color: #FFFFFF;
			cursor: pointer;
			&:hover{
				
			}
		}
	}

	.addressUL {
		margin-top: 35px;
	}

	.addressTitle {
		background: #BE9775;

		p {
			color: #FFFFFF !important;
		}

	}
	.pfont{
		font-size: 20px!important;
		line-height: 30px!important;
	}
	
	.addressli {
		height: 80px;
		border-bottom: 1px solid #F0F0F0;

		p {
			text-align: center;
			font-size: 22px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 32px;
			padding: 0 10px;

			&:nth-child(1) {
				width: 10%;
			}

			&:nth-child(2) {
				width: 25%;
			}

			&:nth-child(3) {
				width: 30%;
			}

			&:nth-child(4) {
				width: 15%;
			}

			&:nth-child(5) {
				width: 20%;

				button {
					padding: 0 10px;
				}
			}
		}

	}

	.dialogBox {
		h2 {
			text-align: center;
			padding-bottom: 20px;
			font-size: 28px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		.el-dialog__body {
			padding: 10px 20px;
		}
		.el-form-item__label {
			
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #575757;
		}
		.el-input__inner{
			border-radius: 0px;
			line-height: 32px;
			height: 32px;
		}
		.el-textarea__inner{
			border-radius: 0px;
		}
		.el-dialog__footer{
			text-align: center!important;
			padding-bottom: 40px;
		}
		.el-button{
			border-radius: 0;
			padding: 12px 50px;
			background: #BE9775;
			font-size: 22px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			border: 0;
		}
		.cancleBtn{
			background: #E6E6E6;
			color: #9C9C9C;
			margin-left: 30px;
		}
	}
	
</style>
