<template>
	<div id="" class="hotbg bg2">
		<nav-box></nav-box>
		<div id="" class="hottopimg">
			<img src="../../assets/img/topImg.png">
		</div>

		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<h2 class="H2title">
					<p>XISHAN SURVEY</p>
					<span id="">
						景点图集
					</span>
					<div id="" class="guidtitle">
						<a href="/">首页></a><a>景点图集</a>
					</div>
				</h2>
			
				<div id="" class="atlasBox">
					<p id="" class="atlasArrow atlasArrowLeft" @click="moveRight">
						<i class="el-icon-arrow-left"></i>
					</p>
					<p id="" class="atlasArrow atlasArrowRight" @click="moveLeft">
						<i class="el-icon-arrow-right"></i>
					</p>
					
						<div id="" class="atlasulBox" ref="atlasulBox">
							<ul id="" class="atlasul flex" :style="{'width':ulwidth+'px','left':leftMove+'px'}">
								<li v-for="(item,index) in list" :style="{'width':liBoxwidth+'px'}">
									<h4>{{item.groName}}</h4>
									<div id="" class="flex atlasliBottom">
										<h3>{{item.groName}}</h3>
										<div id="" class="atlasBottombox" @click="previewImg(item)">
											<div id="" class="atlasBottomimg">
												<img :src="item.groShowUrl" >
											</div>
											<p>《{{item.groName}}景点图集 》</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
				</div>
			</el-col>
		</el-row>
		<el-image-viewer v-if="showViewer" :on-close="()=>{showViewer=false}" :url-list="imgList" />
		<footerBox />
	</div>
</template>

<script>
	export default {
		components: {
			'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
		},
		data() {
			return {
				list: [],
				listTotal: 3,
				showMore: false,
				showViewer: false,
				imgList: [],
				liBoxwidth:null,
				ulwidth:null,
				leftMove:0
			}
		},
		created() {
			this.getData();
			
			
		},
		methods: {
			moveLeft() {
			  let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
			  let moveWith = ulBoxwidth - this.ulwidth
			  if (this.leftMove > moveWith) {
			    this.leftMove = this.leftMove - (this.liBoxwidth*3)
			  }
			},
			moveRight() {
			 let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
			 let moveWith = ulBoxwidth - this.ulwidth
			  if (this.leftMove < 0) {
			    this.leftMove = this.leftMove + (this.liBoxwidth*3)
			  }
			},
			getData() {
				let data = {
					groPlacement: 1,
					groIsShow: true,
					page: 1,
					limit: 50
				};
				this.$http.getAlbum(data).then(res => {
					this.list = res.data.data.items;
					let ulBoxwidth = this.$refs.atlasulBox.offsetWidth;
					
					this.liBoxwidth=ulBoxwidth/3;
					this.ulwidth=this.liBoxwidth*this.list.length
				})
			},
			haveMore() {
				this.showMore = true;
				this.listTotal = 4
			},
			previewImg(item) {
				let data = {
					limit: 50,
					page: 1,
					picGroId: item.groId
				}
				this.$http.getPictures(data).then(res => {
					let imgs = [];
					if (res.data && res.data.data && res.data.data.items.length > 0) {
						res.data.data.items.forEach(item => {
							imgs.push(item.picUrl)
						})
					}
					this.imgList = imgs;
					this.showViewer = true;
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.bg2 {
		background-color: #fbf9f7;
	}
	.h3Title{
		
		font-size: 32px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		padding: 50px 0;
	}
	

	

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.atlasBox{
		width: 100%;
		background: url(../../assets/image/atlas.png) no-repeat;
		position: relative;
	}
	.atlasArrow{
		position: absolute;
		top:30px;
		width: 60px;
		height: 60px;
		border: 2px solid #bf9978;
		border-radius: 50%;
		color: #bf9978;
		font-size: 30px;
		line-height: 60px;
		text-align: center;
		cursor: pointer;
	}
	.atlasArrowLeft{
		left:50px ;
	}
	.atlasArrowRight{
		right: 50px ;
	}
	.atlasulBox{
		width: 80%;
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		min-height: 900px;
		.atlasul{
			position: absolute;
			top:0;
			left: 0;
			justify-content: flex-start;
			padding-top:42px;
			min-height: 200px;
			transition: 1s;
			li{
				cursor: pointer;
				box-sizing: border-box;
				padding: 0 9px;
				&:nth-child(2n-1){
					.atlasliBottom{
						padding-top:260px;
						&::after{
							content: '';
							height: 53%;
							}
					}
					
				}
				h4{
					margin-left: 2px;
					font-size: 26px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #AE7520;
					line-height: 42px;
					position: relative;
					margin-bottom: 26px;
					&::after{
						content: '';
						position: absolute;
						left: 5px;
						top:42px;
						width: 4px;
						height: 20px;
						background: #E2C0A3;
					}
				}
			}
		}
	}
	.atlasliBottom{
		padding-top:143px;
		position: relative;
		
		&::after{
			content: '';
			position: absolute;
			width: 1px;
			height: 42%;
			left: 9px;
			top:0;
			background-color: #E2C0A3 ;
		}
		h3{
			margin-top: 20%;
			width: 20px;
			height: 39px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #AE7520;
		}
		.atlasBottombox{
			flex: 1;
			border: 10px solid #ffe7d3;
			background-color: #ffffff;
			margin-left: 16px;
			.atlasBottomimg{
				width: 100%;
				padding-bottom: 75%;
				position: relative;
				img{
					position: absolute;
					width: 100%;
					height: 100%;
				}
			}
			p{
				padding: 30px 0px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #575757;
			}
		}
	}
	.hottopimg{
		width: 100%;
		img{
			width: 100%;
			height: auto;
		}
	}
</style>
