<template>
	<div id="">
		<nav-box></nav-box>
		<div id="" class="sutopimg">
			<img src="../../assets/img/topImg.png">
		</div>
		<div id="" class="sutopimgbg">
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<h2 class="H2title">
						<p>XISHAN SURVEY</p>
						<span id="">
							西山概况
						</span>
						<div id="" class="guidtitle">
							<a href="/">首页></a><a href="/">印象西山></a><a href="/">西山概况</a>
						</div>
					</h2>
					<video width="100%" autoplay="autoplay" controls="controls" muted="muted" loop="loop"
						style="display: inline-block;height: 600px;	width: 100%;background-color: #000000;">
						<source src="https://img.whzsh.com/video/xishan.mp4" type="video/mp4">
					</video>
					<h4 class="subTitle">省级风景名胜区 - 三国东吴文化历史景点</h4>

					<p class="surveyFont"> 鄂州西山风景旅游区位于长江中游南岸，距华中大都市武汉68公里。北临长江，与黄州赤
						壁相望；南濒洋澜，同万顷碧波相连；东接繁华闹市，西枕百里樊川，襟江带湖，拔地而起。旅游区总面积165公顷，以三国文化、佛教文化、名人文化及自然山水为四大核心资源板块，涵盖吴王避暑宫、松风阁、九曲亭、古灵泉寺及秀园等著名景点，历史遗存数量丰富，文化内涵积淀深厚，多项资源具有唯一性，拥有很高的科学研究价值、历史文化价值和观赏游憩价值。
					</p>
				</el-col>
			</el-row>
		</div>
		<div id="" class="moremsgbox">
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<ul class="moremsgul">
						<li class="moremsgOne">
							<div id="" class="moremsgcontent ">
								<h3>观赏游憩价值</h3>
								<p> 鄂州西山风景旅游区，钟灵毓秀。以吴王故都为特色的三国文化景观，以黄庭坚、
									苏轼为代表的人文景观和以古灵泉寺为标志的佛教文化景观在此巧妙融和，交相辉映，
									大放异彩，对游客而言，无论是感官上的视觉冲击，还是精神上的文化滋润，都是一
									种由内而外的审美体验。</p>
								<p class="marginTop">
									四季皆美景，处处是风光，全年亦可游</p>
								<p>
									西山风景旅游区平地突起，傲视长江，境内六溪七泉，秀谷藏幽，集古、幽、奇、雄
									于一身，突出了“八字屏山画几重，蒸云濯雨翠尤浓”的自然山水特点；松林、竹坞、梅圃、
									花海，景色各异，亭、台、 楼、阁园林小筑，点缀得当。身临其境，如悠然南山。</p>
								<p class="marginTop">
									旅游区空间广阔，旅游环境容量巨大</p>
								<p>
									根据旅游区实际情况，吴王避暑宫、古灵泉寺、秀园、松风阁、九曲亭、庞统读书处等核心
									景点应采用空间承载量测算方法。

								</p>


							</div>
							<div id="" class="moremsgImg marginLeft">
								<el-carousel height="600px" arrow="always">
									<el-carousel-item v-for="item in 4" :key="item">
										<img style="height: 100%;" src="../../assets/image/sur2.png">
									</el-carousel-item>
								</el-carousel>
							</div>
						</li>
						<li class="moremsgOne">
							<div id="" class="moremsgImg">
								<el-carousel height="600px" arrow="always">
									<el-carousel-item v-for="item in 4" :key="item">
										<img style="height: 100%;" src="../../assets/image/sur3.png">
									</el-carousel-item>
								</el-carousel>
							</div>

							<div id="" class="moremsgcontent marginLeft">
								<h3>历史文化科学价值</h3>
								<p>鄂州市西山风景旅游区在历史更迭中形成了三国吴王文化、佛教文化和名人文化，具有极高的历史文化价值；旅游区内百年雄雌银杏树及珍贵植物物种，极具科学研究价值。依据评分标准，景观的历史文化科学价值自评得分为14分。
								</p>

							</div>
						</li>
						<li class="moremsgOne">
							<div id="" class="moremsgcontent ">
								<h3>珍稀或奇特程度</h3>
								<p class="marginTop">1.山水人文组合，世间罕见</p>
								<p>
									西山风景旅游区以自然山水为底蕴，将吴王胜迹与贤士墨迹、佛教禅迹有机结合，构成
									的组合景点极具美、雅、静、雄的气质。既能满足游客吟诗作画、体验闲情的雅致需求，也
									能领略吴王孙权以武为昌、三分天下的壮志与野心，更能体味江南“净土宗”佛教的兴衰变
									迁。英雄避暑、才子作诗、参禅论道，三种文化遥相呼应，景点组合独特，世间少有。</p>
								<p class="marginTop">
									2. 吴王避暑宫,无可比拟</p>
								<p>
									据史记载，三国时期，孙权大兴木营修建武昌城和皇家宫殿，因西山气候凉爽，即在寒溪建造避暑行宫。吴王避暑宫规模宏大，建筑群落历史底蕴浓厚，文化内蕴深邃，人间独有。</p>
								<p class="marginTop">
									3. 古灵泉寺，鄂州最大的宗教活动场所</p>
								<p>
									古灵泉寺涵盖一堂三泉六殿，是一座佛教与历史文化相融合的寺院。千百年来历尽沧桑，几度兴废，但香火未断,信众每临于寺，无不化干戈为玉帛，已致民风纯朴，安稳一方。经过数千年的沉淀与发展，被誉为鄂州市十大重点寺庙的古灵泉寺，已然成为鄂州最大的宗教活动场所及宗教文化游览胜地，其珍稀或奇特程度，在湖北省境内乃至全国范围，也不容小觑。


								</p>

							</div>
							<div id="" class="moremsgImg marginLeft" style="">
								<el-carousel arrow="always" style="">
									<el-carousel-item v-for="item in 4" :key="item">
										<img style="height: 100%;width: 100%;" src="../../assets/image/sur1.png">
									</el-carousel-item>
								</el-carousel>
							</div>
						</li>
						<li class="moremsgOne">
							<div id="" class="moremsgImg">
								<el-carousel height="600px" arrow="always">
									<el-carousel-item v-for="item in 4" :key="item">
										<img style="height: 100%;" src="../../assets/image/sur4.png">
									</el-carousel-item>
								</el-carousel>
							</div>

							<div id="" class="moremsgcontent marginLeft">
								<h3>规模与丰度</h3>
								<p>鄂州西山风景旅游区同时满足了《景观质量评价细则》“规模与丰度”第二
									级中 的三个条件，即资源实体体量很大，基本类型数量将近40种，资源实体疏密
									度良好。</p>
								<p class="marginTop">
									1、资源实体体量很大</p>
								<p>
									鄂州西山风景旅游区面积2.53平方公里，景区旅游资源类型十分丰富，游憩机
									会多样，可以开发出丰富多样的旅游产品，能为游客提供多样化的旅游活动体验。</p>
								<p class="marginTop">
									2.资源实体疏密度良好</p>
								<p>
									鄂州西山风景旅游区旅游资源空间分布具有相对集中的特点，自然景观和人文
									景观相间分布，整体疏密度优良，十分有利于旅游产品开发和线路组织。</p>
								<p class="marginTop">
									3.资源实体基本类型超过30种</p>
								<p>
									依据中华人民共和国国家标准《旅游资源分类、调查与评价》GB/T18972-
									2017），鄂州西山风景旅游区旅游资源可以划分为8个主类、19个亚类和40个基本类
									型，拥有85（个）旅游资源单体。



								</p>

							</div>
						</li>
						<li class="moremsgOne">
							<div id="" class="moremsgcontent ">
								<h3>完整性</h3>
								<p class="marginTop">1.旅游区具有完整的文化系统</p>
								<p>
									鄂州西山风景旅游区文化资源丰富，拥有三国历史文化、佛教文化和名人文化，文化系统完整。目前，景区在经营过程中，加强对现有文物的维护和保护，并且借助研学的方式传承文化，这些举措都使得旅游区的文化系统得以完整保存。
								</p>
								<p class="marginTop">
									2.旅游区具有完整的生态系统 </p>
								<p>
									旅游区集森林、溪涧峡谷、泉池湖泊于一体，生态系统完整。旅游区内植被种类丰富，现存花卉竹林180余种，昆虫兽类132余种，植被覆盖率达98%以上，生态系统丰富多样。当前，旅游区严格按照《中华人民共和国环境保护法》、《鄂州西山风景名胜区总体规（2016-2030）》等相关要求，对景区的自然景观进行严格的保护，同时，对游客行为活动进行控制和引导，尽量将人为活动对生态环境的影响减少到最小，较好的维持了景区内自然景观的完整性，保证生态体系稳定和可持续发展。
								</p>
								<p class="marginTop">
									3.旅游区人文景观整体保护状态良好</p>
								<p>
									旅游区的文化景观主要包括吴王避暑宫、九曲亭、松风阁、秀园及西山庵等。为加强对旅游区内文化景观的保护，2015年对九曲亭、望楚亭、试剑石、石门开等多处景点进行改造和修葺，经过一年的施工，修旧如旧，完整地体现了原来景点的建筑艺术和文化内涵。现今，市政府每年投入近千万元专项资金，用以巩固提档景区建设，维持西山风景旅游区正常运转。

								</p>

							</div>
							<div id="" class="moremsgImg marginLeft">
								<el-carousel height="600px">
									<el-carousel-item v-for="item in 4" :key="item" arrow="always">
										<img style="height: 100%;" src="../../assets/image/sur5.png">
									</el-carousel-item>
								</el-carousel>
							</div>
						</li>
					</ul>
				</el-col>
			</el-row>
		</div>


		<footerBox />
	</div>
</template>

<script>
	export default {

		components: {

		},
		data() {
			return {

			}
		}
	}
</script>

<style lang="less">
	.sutopimgbg {
		background: url(../../assets/image/surbg1.png) no-repeat 100%;
		padding-bottom: 150px;
	}


	.marginLeft {
		margin-left: 70px;
	}

	.marginTop {
		margin-top: 20px;
		margin-bottom: 6px;
	}

	.moremsgbox {
		width: 100%;
		min-height: 964px;
		padding-bottom: 100px;

		// background:url(../../assets/img/surbg.png) no-repeat cover;
		background: url(../../assets/image/surbg.png) no-repeat 100%;
		background-size: 100% 100%;

		.moremsgul {
			padding: 40px 0;

			.moremsgOne {
				margin-top: 150px;
				display: flex;
				justify-content: flex-start;

				.moremsgcontent {
					flex: 1;

					h3 {
						width: 457px;
						height: 142px;
						background: url(../../assets/image/surbg3.png) no-repeat 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 40px;
						font-family: PingFang SC;
						// font-weight: bold;a
						color: #AE7520;
						margin-bottom: 47px;
					}

					p {
						width: 100%;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #2C2C2C;
						text-indent: 2em;
						text-align: justify;
					}
				}

				.moremsgImg {
					width: 50%;
					position: relative;
					padding-bottom: 40%;

					.el-carousel {
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
					}

					.el-carousel__container {
						width: 100% !important;
						height: 100% !important;

					}

					.el-carousel__item {
						width: 95%;
						margin-left: 2.5%;
					}

					.el-carousel__indicators {
						display: none;
					}

					::v-deep .el-carousel {
						z-index: 110000
					}

					.el-carousel__arrow {
						background-color: #FFFFFF !important;
						box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
						z-index: 99999;
						width: 50px;
						height: 50px;

						i {
							color: #333333;
							font-size: 24px;
						}
					}

					.el-carousel__arrow--left {
						left: -0px !important;

					}

					.el-carousel__arrow--right {
						right: -0px !important;

					}
				}

				// div {
				// 	// margin: 63px 0;
				// 	flex: 1;
				// 	color: #6b6a68;
				// 	font-size: 24px;
				// 	line-height: 46px;
				// }
			}
		}
	}

	.subTitle {
		font-size: 32px;
		left: 36px;
		color: #333;
		margin: 64px 0;
	}

	.sutopimg {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;

		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;

			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}

		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 546px;
			height: 2px;
			background-color: #d8c6a3;

		}

		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 546px;
			height: 2px;
			background-color: #d8c6a3;

		}

		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../../assets/img/fontbg.png) no-repeat bottom;

		}
	}

	.surveyFont {
		font-size: 18px;
		font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
		// font-weight: 400;
		color: #575757;
		line-height: 34px;
		text-indent: 2em;
	}

	.surimgbox {
		width: 784px;
		margin: 120px auto 120px;

		img {
			width: 100%;
			height: auto;
		}
	}
</style>
