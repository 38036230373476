<template>
	<div class="">
		<nav-box></nav-box>
		<div id="app" class="main site_b70" style="padding-bottom: 20px;">
			<div class="site_banner">
				<img src="../../assets/img/xishan-bg1.png">
			</div>
			
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<Title title="游园游记"></Title>
					
					<div class="">
						<div class="news-part">`
							<ul class="fixed" style="display: flex; flex-flow: wrap;">
								<li  class="public_li" v-for="(item,index) in items" :key="index">
									<a :href="'/newDetail?id='+item.id" class="tran_scale">
										<div data-ratio="0.5625" class="img ratio-img" style="height: 210px;"><img
												:src="'https://www.ezhouxishan.com/hdstorApi/web'+item.coverPic"
												onerror="lod(this)"></div>
										<div class="txt">
											<h2 class="clamp fnt_24">{{item.title}}</h2>
											<p class="clamp"></p>
											<span class="p_time">{{item.updateTime}}</span>
										</div>
									</a>
								</li>
							</ul> <span class="more fnt_18"><em>加载更多新闻</em></span>
						</div>
					</div>
				</el-col>
			</el-row>
			
		</div>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import{getArticleList , getArticleDetail} from '@/api/content.js'
	export default{
		data() {
			return {
				items: []
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				let data={
					categoryId:16
				}
				getArticleList(data).then(res=>{
					if(res.data && res.data.data && res.data.data.records){
						this.items = res.data.data.records
					}
				})
			}
		},
	}
</script>

<style>
</style>
