<template>
	<div id="" style="background-color: #f8f8f8;">
		<nav-box></nav-box>
		<div id="" class="topimg">
			<img src="../../assets/img/topImg.png">
		</div>

		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<h2 class="H2title">
					<p>XISHAN SURVEY</p>
					<span id="">
						媒体聚焦
					</span>
					<div id="" class="guidtitle">
						<a href="/">首页></a><a href="/">印象西山></a><a href="/">媒体聚焦</a>
					</div>

				</h2>
				<el-row v-if="page==1" :gutter="20" type="flex" class="row-bg" justify="space-between">
					<el-col :span="9" :xs="24">
						<div id="" class="imgnew" v-if="items&&items.length>0">
							<div id="" class="inbox media-img">
								<a :href="'/newDetail?id='+items[0].id" target="_blank">
									<img :src="'https://www.ezhouxishan.com/hdstorApi/web'+items[0].coverPic">
								</a>
							</div>

							<h3>
								<a :href="'/newDetail?id='+items[0].id" target="_blank">{{items[0].title}}</a>
							</h3>
							<p class="elfive subtitle" >
								<a :href="'/newDetail?id='+items[0].id" target="_blank">{{items[0].contentValidity}}</a>
							</p>
						</div>
					</el-col>
					<el-col :span="15" :xs="24">
						<div id="" class="rightnew" v-if="items&&items.length>1">

							<div id="" class="rnTopTitle">
								<h3>
									<p>01</p>
									<h6>{{items[1].updateTime|timeFilters}}</h6>
								</h3>
								<div id="" class="rTitle">
									<a :href="'/newDetail?id='+items[1].id" target="_blank">{{items[1].title}}</a>
								</div>
								<i class="el-icon-right"></i>
							</div>
							<p class="elfive subtitle">
								<a :href="'/newDetail?id='+items[1].id" target="_blank">{{items[1].contentValidity}}</a>
							</p>
						</div>
						<div id="" class="rightnew" v-if="items&&items.length>2">
						
							<div id="" class="rnTopTitle">
								<h3>
									<p>02</p>
									<h6>{{items[2].updateTime|timeFilters}}</h6>
								</h3>
								<div id="" class="rTitle">
									<a :href="'/newDetail?id='+items[2].id" target="_blank">{{items[2].title}}</a>
								</div>
								<i class="el-icon-right"></i>
							</div>
							<p class="elfive subtitle">
								<a :href="'/newDetail?id='+items[2].id" target="_blank">{{items[2].contentValidity}}</a>
							</p>
						</div>
					</el-col>
				</el-row>
				<div id="" class="newboxs">
					<ul class="newboxsul">
						<li v-for="(item,index) in items" :key="index" v-if="(page==1&& index>2)||page>1">
							<p>{{item.updateTime|timeFilters}}</p>
							<h5 class="el">
							<a :href="'/newDetail?id='+item.id" target="_blank">{{item.title}}</a>
							</h5>
							<i class="el-icon-right"></i>
						</li>
					</ul>
					
					<div id="" class="flex pagination" style="padding: 20px;display: flex;justify-content: center;">
						<el-pagination
						  background
						  layout="prev, pager, next"
						  :page-size="10"
						  :page-count="totalPage"
						  :current-page="page"
						   @current-change="handleCurrentChange"
						  >
						</el-pagination>
					</div>
					
				</div>
			</el-col>
		</el-row>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import{getArticleList , getArticleDetail} from '@/api/content.js'
	export default {
		filters:{
			timeFilters(time){
				console.log(time)
				let string=''
				if(time){
					string=time.substring(0,10)
				}
				
				return string
			}
		},
		data() {
			return {
				items:[],
				page:1,
				totalPage:1
			}
		},
		created() {
			this.getData();
		},
		methods:{
			handleCurrentChange(page){
				this.page =page;
				this.getData()
			},
			getData(){

				let data={
					categoryId:14,
					current:this.page
				}
				getArticleList(data).then(res=>{
					this.totalPage =res.data.data.pages;
					if(res.data && res.data.data && res.data.data.records){
						this.items = res.data.data.records
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.topimg {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;

		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;

			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}

		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 546px;
			height: 2px;
			background-color: #d8c6a3;

		}

		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 546px;
			height: 2px;
			background-color: #d8c6a3;

		}

		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../../assets/img/fontbg.png) no-repeat bottom;

		}
	}

	.imgnew {
		background-color: #FFFFFF;
		width: 100%;

		.inbox {
			width: 100%;
			height: 370px;
			overflow: hidden;

			img {
				width: 100%;
				height: auto;
			}
		}


		h3 {
			padding: 10px 20px;
			font-size: 26px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #000000;

		}

		p {
			padding: 10px 20px;
			line-height: 24px;
			height: 75px;
			font-size: 14px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #747474;
		}
	}

	.rightnew {
		background-color: #FFFFFF;
		margin-bottom: 20px;
		padding: 0px 60px;

		&:hover {
			h3 {
				p {
					color: #F2B963 !important;
				}

				h6 {
					color: #F2B963 !important;
				}

			}

			i {
				color: #F2B963 !important;
			}


		}

		.rnTopTitle {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 14px 0 35px 0;
			border-bottom: 1px solid #B2B2B2;

			h3 {
				p {
					width: 62px;
					line-height: 66px;
					color: #000000;
					font-size: 50px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
				}

				h6 {
					height: 16px;
					font-size: 12px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #000000;

				}
			}

			.rTitle {
				margin-left: 2%;
				width: 80%;
				height: 35px;
				font-size: 26px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #000000;
			}

			i {
				font-size: 30px;
				font-weight: bold;
			}
		}
	}

	.subtitle {
		padding-top:20px;
		line-height: 24px;
		height: 120px;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #747474;
		a{
			color: #575757;
		}
	}
	.newboxs{
		background-color: #FFFFFF;
		padding: 0 40px;
		margin-bottom: 50px;
		.newboxsul{
			li{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				border-bottom: 1px solid #B2B2B2;
				padding: 27px 0;
				&:hover{
					color: #F2B963 !important;
					h5{
						color: #F2B963 !important;
					}
				}
				p{
					width: 120px;
					
					padding: 0 30px 0 0;
					line-height: 21px;
					font-size: 16px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #B2B2B2;
				}
				h5{
					width: 83%;
					height: 31px;
					font-size: 24px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					
				}
				i{
					font-size: 40px;
					font-weight: bold;
				}
			}
		}
	}
	.pagination{
		.el-pager li{
			width: 42px;
			line-height: 42px;
			height: 42px;
		}
		.el-pager li.active{
			background-color: #D1281D!important;
		}
		.el-pager li:hover{
			color: #D1281D!important;
		}
		.el-pagination button{
			width: 42px;
			line-height: 42px;
			height: 42px;
		}
	}
	// .media-img{
	// 	height: 0 !important;
	// }
</style>
