<template>
	<div class="home" id="homeBox">
		<img class="indexbg" src="../../assets/img/indexbg.png">
		<img class="bottombg" src="../../assets/img/bottombg.png">
		<nav-box></nav-box>

		<div id="bannerbox" class="bannerbox">
			<el-carousel :interval="5900" height="980px" @change="carouselChange">
				<el-carousel-item v-for="(item,index) in swiperList" :key="index">
					<img style="height: 100%;width:100%;object-fit: cover;object-fit: cover;" class="bannerimg"
						:class="{'bannerimgscale':carouselIndex==index}" :src="item.picUrl">
				</el-carousel-item>
			</el-carousel>
			<div id="" class="carouselDot">
				<div id="" class="carouselDotO" v-for="(item,index) in swiperList">
					<i class="iconfont " style="color: #FFFFFF;" v-if="carouselIndex==index">&#xe64d;</i>
					<p v-if="carouselIndex==index">西山公园</p>
				</div>
			</div>
		</div>
		<div id="" class="contentbg">

			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<h2 class="H2title">
						<p>WONDERFUL ATLAS</p>
						<span id="">
							精彩图集
						</span>
					</h2>
					<div id="" class="imgbox">
						<el-row :gutter="10">
							<el-col :span="14">
								<div id="" class="animate__animated imgboxone" :class="{'animate__fadeInLeft':imgshow}">
									<!-- <img src="../../assets/img/img5.png"> -->
									<video width="100%" autoplay="autoplay" muted="muted" loop="loop"
										style="display: inline-block;height: 100%;object-fit: cover;border-radius: 2px;">
										<!-- <source src="https://img.whzsh.com/video/xishannie.mp4" type="video/mp4"> -->
										<source src="https://img.whzsh.com/video/xishan.mp4" type="video/mp4">
										
									</video>
								</div>
								<el-row :gutter="10" style="margin-top: 10px;">
									<el-col :span="10">
										<div id="" class="animate__animated imgboxtwo imgboxALL"
											:class="{'animate__fadeInLeft':imgshow}">
											<div id="" class="shadowDiv"></div>
											<img src="../../assets/img/indeximg1.png">
											<p>避暑宫</p>
										</div>
									</el-col>
									<el-col :span="14">
										<div id="" class="animate__animated imgboxtwo imgboxALL"
											:class="{'animate__fadeInLeft':imgshow}">
											<div id="" class="shadowDiv"></div>
											<img src="../../assets/img/indeximg2.png">
											<p>古灵泉寺</p>
										</div>
									</el-col>

								</el-row>
							</el-col>
							<el-col :span="10">
								<div id="" class="animate__animated imgboxtwo imgboxALL"
									:class="{'animate__fadeInRight':imgshow}">
									<div id="" class="shadowDiv"></div>
									<img src="../../assets/img/indeximg4.png">
									<p class="animate__animated ">松风阁</p>
								</div>
								<el-row :gutter="10" style="margin-top: 10px;">
									<el-col :span="12">
										<div id="" class="animate__animated imgboxtwo imgboxALL"
											:class="{'animate__fadeInRight':imgshow}">
											<div id="" class="shadowDiv"></div>
											<img src="../../assets/img/indeximg3.png">
											<p class="animate__animated ">庞统读书处</p>
										</div>
										<div id="" style="margin-top: 10px;"
											class="animate__animated imgboxtwo imgboxALL"
											:class="{'animate__fadeInRight':imgshow}">
											<div id="" class="shadowDiv"></div>
											<img src="../../assets/img/indeximg6.png">
											<p class="animate__animated ">秀园</p>
										</div>
									</el-col>
									<el-col :span="12">
										<div id="" class="" class="animate__animated imgboxthree imgboxALL"
											:class="{'animate__fadeInRight':imgshow}">
											<div id="" class="shadowDiv"></div>
											<img src="../../assets/img/indeximg5.png">
											<p class="animate__animated ">武昌楼</p>
										</div>
									</el-col>
								</el-row>

							</el-col>
						</el-row>
					</div>
					<div id="" class="lookmore">
						<span id="">
							查看更多
						</span>
					</div>
					<div id="" class="hotView">
						<div id="" class="hotViewTitle">
							<h3>热门景点</h3>
							<h4>发现一些我们最受欢迎的景点...</h4>
						</div>
						<el-row :gutter="50" type="flex" class="row-bg" justify="space-between">
							<el-col :span="6">
								<div id="" class=" hotViewOne">
									<img src="../../assets/img/img1.png"
										:style="{'top':moretoShow?'-'+moretoShowHeight+'px':'0px'}">
									<div class="hotViewDIV">
										<h1>N0.1</h1>
										<h6>读书堂</h6>
										<p>相传孙权建都武昌期间，到西山避暑宫避暑时，常去读书堂秉烛夜读，博览群书。后来游人到此，听竹雨潇潇，石泉潺潺，书声朗朗，
											于是有“书堂夜雨”之说，为古代鄂州八景之一。
										</p>
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div id="" class=" hotViewOne">
									<img src="../../assets/img/img2.png"
										:style="{'top':moretoShow?moretoShowHeight+'px':'0px'}">
									<div class="hotViewDIV">
										<h1 class="hotViewh11">N0.2</h1>
										<h6>古灵泉寺</h6>
										<p>古灵泉寺座落于绿树成萌的西山东坡之上,故又 名“西山寺”为东晋太元年间高僧慧 远所建,是中 国佛教“净土宗“的发祥地。</p>
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<div id="" class=" hotViewOne">
									<img src="../../assets/img/img3.png"
										:style="{'top':moretoShow?'-'+moretoShowHeight+'px':'0px'}">
									<div class="hotViewDIV">
										<h1 class="hotViewh12">N0.3</h1>
										<h6>武昌楼</h6>
										<p>武昌楼是1998年为重现吴王避暑宫原貌而迁建的吴王避暑宫建筑群中的一座标志性建筑，矗立于椅子山项，楼高五层，楼门横额上的飘逸道劲"武昌楼三个大字，是郭沫若的手迹。
										</p>
									</div>
								</div>
							</el-col>
							<el-col :span="6">
								<!-- <div id="" class="animate__animated hotViewOne"
									:class="{'animate__fadeInUp':moretoShow}"> -->
								<div id="" class=" hotViewOne">
									<img src="../../assets/img/img4.png"
										:style="{'top':moretoShow?moretoShowHeight+'px':'0px'}">
									<div class="hotViewDIV">
										<h1 class="hotViewh13">N0.4</h1>
										<h6>秀园</h6>
										<p>秀园典雅秀丽，亭、廊、阁、榭依山傍水，松、竹、梅、桔天然巧成。秀园内有梯廊、流音阁、香”雪亭、幽思亭停云馆等多处景点。盆景花木，着意点缀，山水阁榭巧妙安排。
										</p>
									</div>
								</div>
							</el-col>

						</el-row>
					</div>
					<div id="" class="toEncounterBOX">
						<h2 class="H2title">
							<p>ENCOUNTFR XISHAN</p>
							<span id="">
								邂逅鄂州
							</span>
						</h2>
						<div id="" class="toEncounter">
							<el-row :gutter="30" type="flex" class="row-bg" justify="space-between">
								<el-col :span="6">
									<div id="" class="toEncounterone">
										<div id="" class="imgbox">
											<img src="../../assets/img/img.jpg">
										</div>

										<div id="" class="toEncounterMsg">
											<h2>逛·景点</h2>
											<p>吴王避暑宫</p>
											<p>古灵泉寺</p>
											<p>武昌楼</p>
											<p>秀园</p>
											<p>...</p>
											<button class="toEncounterbtn" @click="navto('/four')"> 查看更多</button>
										</div>
										<div class="toEncounterObtm">
											<h2>逛·景点</h2>
											<p>吴王避暑宫</p>
											<p>古灵泉寺</p>
											<p>武昌楼</p>
											<p>秀园</p>

											<p>...</p>
											<div id="" class="toEncounterObdiv">
												<h2>景点</h2>
												<h3>Scenic spot</h3>
												<p>“省级风景名胜区 <br>三国东吴文化历史景点<br>”</p>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="6">
									<div id="" class="toEncounterone">
										<div id="" class="imgbox">
											<img src="../../assets/img/img12.png">
										</div>

										<div id="" class="toEncounterMsg">
											<h2>品.鄂州</h2>
											<p>沙窝豆丝</p>
											<p>太和千张</p>
											<p>吴家大院</p>
											<p>周屴板鸭</p>
											<p>武昌鱼</p>
											<button class="toEncounterbtn" @click="navto('/food')"> 查看更多</button>
										</div>
										<div class="toEncounterObtm">
											<h2>品.鄂州</h2>

											<p>沙窝豆丝</p>
											<p>太和千张</p>
											<p>吴家大院</p>
											<p>周屴板鸭</p>
											<p>武昌鱼</p>
											<div id="" class="toEncounterObdiv">
												<h2>美食</h2>
												<h3>Delicious food</h3>
												<p>“湖北省历史文化名城，素有[百湖之市][鱼米之乡]<br>等称号”</p>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="6">
									<div id="" class="toEncounterone">
										<div id="" class="imgbox">
											<img src="../../assets/img/img13.png">


										</div>
										<div id="" class="toEncounterMsg">
											<h2>宿·客栈</h2>
											<p>天龙大酒店</p>
											<p>飞鹅假日酒店</p>
											<p>南悦大酒店 </p>
											<p>维也纳国际酒店</p>

											<button class="toEncounterbtn" @click="navto('/site')"> 查看更多</button>
										</div>
										<div class="toEncounterObtm">
											<h2>宿·客栈</h2>
											<p>天龙大酒店</p>
											<p>飞鹅假日酒店</p>
											<p>南悦大酒店 </p>
											<p>维也纳国际酒店</p>
											<p>...</p>
											<div id="" class="toEncounterObdiv">
												<h2>客栈</h2>
												<h3>Inn</h3>
												<p>“周边名宿客栈环境幽雅，温馨舒适”</p>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="6">
									<div id="" class="toEncounterone">
										<div id="" class="imgbox">
											<img src="../../assets/img/img6.jpg">
										</div>

										<div id="" class="toEncounterMsg">
											<h2>文人骚客</h2>
											<p>苏东坡</p>
											<p>黄庭坚</p>
											<p>彭玉麟</p>
											<p>...</p>
											<button class="toEncounterbtn" @click="navto('/humanity')"> 查看更多</button>
										</div>
										<div class="toEncounterObtm">
											<h2>文人骚客</h2>
											<p>苏东坡</p>
											<p>黄庭坚</p>
											<p>彭玉麟</p>
											<p>...</p>
											<div id="" class="toEncounterObdiv">
												<h2>文化</h2>
												<h3>Culture</h3>
												<p>“引众多文人骚客，在此驻足，为秀山水色写下<br>绝美篇章！”</p>
											</div>
										</div>
									</div>
								</el-col>

							</el-row>
						</div>
					</div>
					<div id="" class="touristGuideBOX">
						<h2 class="H2title">
							<p>TOURIST GUIDE</p>
							<span id="">
								旅游指南
							</span>
						</h2>
						<div id="" class="touristGuideB">
							<el-row :gutter="30" type="flex" class="row-bg" justify="space-between">
								<el-col :span="4">
									<div id="" class="touristGuide " :class="{'animate__flipInY':guidShow}"
										@click="navto('/gonglue')">
										<img src="../../assets/img/index2.png">
										<p>
											游记攻略
										</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div id="" class="touristGuide  " :class="{'animate__flipInY':guidShow}"
										@click="navto('/three')">
										<img src="../../assets/img/index1.png">
										<p>票务信息</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div id="" class="touristGuide  " :class="{'animate__flipInY':guidShow}"
										@click="navto('/four')">
										<img src="../../assets/img/index3.png">
										<p>云游西山</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div id="" class="touristGuide   " :class="{'animate__flipInY':guidShow}" 
										@click="navto('/hotspot')">
										<img src="../../assets/img/index5.png">
										<p>景点图集</p>
									</div>
								</el-col>
								<el-col :span="4">
									<div id="" class="touristGuide  " :class="{'animate__flipInY':guidShow}"
										@click="navto('/traffic')">
										<img src="../../assets/img/index4.png">
										<p>周边交通</p>
									</div>
								</el-col>

							</el-row>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<footerBox />
		<div id="" class="sidebarbox">
			<div id="" class="sidebarleft">
				<img src="../../assets/img/left.png">
				<p>旅游服务</p>
				<span id="">
					<a href="#homeBox" class="el-icon-arrow-up"></a>
				</span>
			</div>
			<div id="" class="sidebarright">
				<ul>
					<li>
						<div id="" class="divli">
							<i class="iconfont">&#xe603;</i><span id="">
								咨询热线
							</span>
						</div>
						<div id="" class="moreMsg">
							<p><span id="">咨询电话：</span><span id="">3209622</span></p>
							<p><span id="">投诉电话：</span><span id="">3209990</span></p>
							<p><span id="">救援电话：</span><span id="">3209568</span></p>
							
							<!-- <p><span id="">市局投诉：</span><span id="">12345</span></p>
							<p><span id="">湖北省旅游局：</span><span id="">027-68892308</span></p>
							 -->
						</div>
					</li>
					<li>
						<a class="qrcodebox" href="javascript:void(0)">
							<img src="../../images/qrcode.jpg">
						</a>
						<div id="" class="divli">
							<i class="iconfont">&#xe6c8;</i><span id="">
								官方二维码
							</span>
						</div>
					</li>
					<li>
						<div id="" class="divli">
							<i class="iconfont">&#xe684;</i><span id="">
								在线咨询
							</span>
						</div>

					</li>
					<li style="border: 0;">
						<div id="" class="divli">
							<i class="iconfont">&#xe617;</i><span id="">
								投诉建议
							</span>
						</div>

					</li>
				</ul>
			</div>
		</div>

		<!--  -->
		<!-- 	<div class="sidebar" :class="SidebarShow?'':'off'">
			<span class="sidebar-close"
				:style="{'visibility': SidebarShow?'visible':'hidden','opacity': SidebarShow?1:0}"
				@click="closeSidebar"></span>
			<span class="sidebar-open"
				:style="{'visibility': SidebarShow?'hidden':'visible','opacity': SidebarShow?0:1}"
				@click="OpenSidebar"></span>
			<ul :style="{'visibility': SidebarShow?'visible':'hidden','opacity': SidebarShow?1:0}">
				<li>
					<a id="sp_btn_creatWebCallConnect" onclick="sp_openWebcallView()" href="javascript:void(0);">
						<img src="../../images/s_site01.png">
					</a>
					<div class="sidebar_p fnt_16 sidebar_customer">
						<p>在线客服</p>
					</div>
				</li>
				<li>
					<a href="javascript:void(0)">
						<img src="../../images/s_site02.png">
					</a>
					<div class="sidebar_p fnt_16 sidebar_phone">
						<dl>
							<dt>官方热线</dt>
							<dd>3209622</dd>
						</dl>
					</div>
				</li>
				<li>
					<a href="javascript:void(0)">
						<img src="../../images/s_site04.png">
					</a>
					<div class="erm">
						<dl>
							<dt><img src="../../images/qrcode.jpg"></dt>
							<dd>微信扫码预定</dd>
						</dl>
					</div>
				</li>
				<li class="back_top" style="display: list-item; opacity: 1;">
					<a href="javascript:void(0)">
						<img src="../../images/s_site05.png">
					</a>
				</li>
			</ul>
		</div> -->


	</div>
</template>

<script>
	import navBox from "@/components/navBox.vue";
	import pieChartsData from "./echarts/pieChartsData.vue";
	export default {
		name: "Home",
		components: {
			// navBox,
			pieChartsData,
		},
		data() {
			return {
				yunClass: true,
				imgshow: false,
				title2: false,
				Packageshow: false,
				moretoShow: false,
				moretoShowHeight: 60,
				moreto2Show: false,
				SidebarShow: true,
				guidShow: false,
				list: [
					// 	{img:'https://gboss.wtown.com/images/2021/12/02/file_d19fa430b6244b77ad03cfa104708fd8.jpg',name:'童话物语',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/09/file_58fd7ecfc59b495089ad4822c86e5234.jpg',name:'汤泉童趣',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/09/file_327d9d017414415a845873b9aacd45c1.jpg',name:'京韵暖冬',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/18/file_5be5ed98bd2c4d41b677c777892f8f07.jpg',name:'跨年迎新',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/02/file_be3c239b50854780b5fce5369c65aa64.jpg',name:'围炉夜话',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/02/file_17db336bb19e47158f5a5a86ad43d6f3.jpg',name:'童梦奇缘',price:'￥2,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/18/file_5be5ed98bd2c4d41b677c777892f8f07.jpg',name:'11',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/02/file_be3c239b50854780b5fce5369c65aa64.jpg',name:'22',price:'￥1,860'},
					// {img:'https://gboss.wtown.com/images/2021/12/02/file_17db336bb19e47158f5a5a86ad43d6f3.jpg',name:'33',price:'￥2,860'},
				],
				listIndex: 0,
				swiperList: [],
				albumList: [],
				articleList: [],
				carouselIndex: 0
			};
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);

		},
		created() {
			this.getList()
		},
		methods: {
			navto(url) {
				this.$router.push(url)
			},
			carouselChange(i) {
				console.log(i)
				this.carouselIndex = i
			},
			//接口
			getList() {
				let data = {
					clientId: 'weixinManagement',
					ticNumber: ''
				}
				this.$http.homeData(data).then(res => {
					let data = res.data.data
					if (data && data.homePicture) {
						// this.swiperList =data.homePicture;
						this.swiperList = [{
							picUrl: require('@/assets/img/banner1.png')
						}, {
							picUrl: require('@/assets/img/banner2.jpg')
						}, {
							picUrl: require('@/assets/img/banner2.png')
						}]
					}
					if (data && data.photoAlbum) {
						this.albumList = data.photoAlbum
						this.list = data.photoAlbum;
						this.haveliWidth()
					}

					if (data && data.scenicArticle) {
						this.articleList = data.scenicArticle;
					}
					if (data && data.tickets) {
						// this.list =data.tickets;
						// this.haveliWidth()
					}
					console.log(data)
				})

			},
			OpenSidebar() {
				this.SidebarShow = true
			},
			closeSidebar() {
				this.SidebarShow = false
			},
			//套餐位置排序
			haveliWidth() {
				let clientWidth = document.body.clientWidth;
				let ulWidth = clientWidth * 0.73;
				let liWidth = ulWidth * 0.3333;
				this.list.forEach((item, index) => {
					item.matrix = `matrix(1, 0, 0, 1, ${(index*liWidth).toFixed(2)}, 0)`
				})
			},
			handleNext() {
				let list = []
				this.list.forEach((item, index) => {
					if (index <= 2) {
						list.push(item)
					}
				})
				this.list.splice(0, 3)
				this.list = this.list.concat(list)
				this.haveliWidth()
			},
			handlePrev() {
				let list = []
				this.list.forEach((item, index) => {
					if (index >= this.list.length - 3) {
						list.push(item)
					}
				})
				this.list.splice(this.list.length - 3, 3)
				this.list = list.concat(this.list)
				this.haveliWidth()
			},
			handleScroll() {
				let scrollTop = document.documentElement.scrollTop;
				if (scrollTop >= 350) {
					this.imgshow = true
				} else {
					this.imgshow = false
				}
				if (scrollTop >= 1600 && scrollTop <= 2000) {
					this.moretoShow = true
				} else {
					this.moretoShow = false
				}
				if (scrollTop >= 2400) {
					this.moreto2Show = true
				} else {
					this.moreto2Show = false
				}
				if (scrollTop >= 3400) {
					this.guidShow = true
				} else {
					this.guidShow = false
				}
				// if(scrollTop >= 1559 ){
				// 	console.log('scrollTop >= 1559')
				// 	this.title1=true
				// 	console.log(this.title1)
				// }


				console.log(document.documentElement.scrollTop);
			},
		},

		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		}
	};
</script>
<style lang="less" scoped>
	.bannerimg {
		-webkit-animation-duration: 6s;
		animation-duration: 6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
		/* Safari 和 Chrome */
	}

	.bannerimgscale {
		animation: myfirst 6s infinite;
	}

	@keyframes myfirst {
		0% {
			transform: scale(1, 1);
		}

		100% {
			transform: scale(1.1, 1.1);
		}
	}

	.home {
		position: relative;
		overflow: hidden;
		z-index: 0;

		.indexbg {
			position: absolute;
			top: 294px;
			left: 0;
			width: 100%;
			height: auto;
			z-index: -1;
		}

		.bottombg {
			width: 100%;
			height: auto;
			position: absolute;
			bottom: 340px;
			left: 0;
			z-index: -1;
		}
	}

	.sidebarbox:hover {
		right: 0px;

		.sidebarright {
			display: block;
		}
	}

	.sidebarbox {
		position: fixed;
		right: -260px;
		// right:0;
		bottom: 10%;
		width: auto;
		// height: 270px;
		background-color: #BC9471;
		border-radius: 5px;
		z-index: 99;
		transition: 1s;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;

		.sidebarleft {
			background-color: #BC9471;
			color: #FFFFFF;
			width: 50px;
			padding: 12px 0px;

			img {
				width: 100%;
				height: auto;
			}

			p {
				display: flex;
				// justify-content: center;
				align-items: center;
				height: 67%;
				text-align: center;
				padding: 0px 10px;
				font-size: 20px;
				line-height: 30px;
				color: #FFFFFF;

				&:hover {

					color: #eb9f05;

				}
			}

			span {
				margin-top: 20px;
				border-top: 0.5px solid rgba(255, 255, 255, 0.3);
				text-align: center;
				display: inline-block;
				width: 100%;
				cursor: pointer;

				&:hover {
					a {
						color: #eb9f05;
					}
				}

				a {
					margin-top: 10px;
					font-size: 30px;
					color: #FFFFFF;
				}
			}
		}

		.sidebarright {

			border-left: 0.5px solid rgba(255, 255, 255, 0.3);

			li {
				padding: 16px 20px;
				border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
				position: relative;

				&:hover {
					.qrcodebox {
						// left: -180px;
						opacity: 1;
					}
				}

				.qrcodebox {
					position: absolute;
					top: -32px;
					left: -180px;
					width: 120px;
					height: 120px;
					z-index: 98;
					opacity: 0;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.divli {
				i {
					margin-right: 10px;
				}

				&:hover {
					color: #eb9f05;
				}

				line-height: 30px;
				font-size: 16px;
				color: #FFFFFF;
			}

			.moreMsg {
				padding: 10px 0;

				p {
					font-size: 14px;
					line-height: 20px;
					color: #FFFFFF;

					&:hover {
						color: #eb9f05;
					}

					span {
						display: inline-block;

						&:nth-child(1) {
							width: 100px;
						}

						&:nth-child(2) {
							width: 120px;
						}
					}
				}

			}
		}
	}

	.imgbox {
		img {
			width: 100%;
			height: 100%;
			transition: 1s;

		}

	}

	.imgboxone {
		width: 100%;
		height: 524px;
		cursor: pointer;
	}

	.imgboxALL {
		position: relative;

		&:hover {
			p {
				opacity: 1;
			}

			.shadowDiv {
				box-shadow: 40px 40px 80px rgba(0, 0, 0, 0.16) inset;

			}
		}

		p {
			opacity: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 40px;
			width: 100%;
			font-size: 20px;
			color: #FFFFFF;
			line-height: 40px;
			text-align: center;
			background-color: rgba(0, 0, 0, 0.46);
		}

		.shadowDiv {
			// opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 9;
		}
	}

	.imgboxtwo {
		width: 100%;
		height: 257px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		border-radius: 0px;

		&:hover {
			img {
				transform: scale(1.2);
			}

		}


	}

	.imgboxthree {
		width: 100%;
		height: 524px;
		position: relative;
		overflow: hidden;
		border-radius: 0px;

		&:hover {
			img {
				transform: scale(1.1);
			}

		}


	}

	// .H2title {
	// 	text-align: center;
	// 	width: 100%;
	// 	padding: 150px 0 60px;
	// 	position: relative;
	// 	z-index: 0;

	// 	p {
	// 		width: 100%;
	// 		text-align: center;
	// 		position: absolute;
	// 		bottom: 128px;
	// 		left: 0;
	// 		font-size: 49px;
	// 		color: #eee5ce;
	// 		z-index: -1;
	// 		font-family: Microsoft YaHei UI;
	// 		letter-spacing: 4px;
	// 	}

	// 	&::after {
	// 		content: '';
	// 		position: absolute;
	// 		bottom: 98px;
	// 		left: 0;
	// 		width: 34%;
	// 		height: 2px;
	// 		background-color: #d8c6a3;

	// 	}

	// 	&::before {
	// 		content: '';
	// 		position: absolute;
	// 		bottom: 98px;
	// 		right: 0;
	// 		width: 34%;
	// 		height: 2px;
	// 		background-color: #d8c6a3;

	// 	}

	// 	span {
	// 		display: inline-block;
	// 		height: 110px;
	// 		width: 380px;
	// 		font-size: 60px;
	// 		text-align: center;
	// 		// color: #a27b2c;
	// 		background: url(../../assets/img/fontbg.png) no-repeat bottom;

	// 	}
	// }



	.contentbg {
		width: 100%;

	}

	.lookmore {
		text-align: center;
		padding: 55px 0;
		cursor: pointer;

		span {
			display: inline-block;
			height: 54px;
			width: 230px;
			font-size: 30px;
			line-height: 54px;
			text-align: center;
			color: #d5b573;
			background: url(../../assets/img/fonttitle2.png) no-repeat bottom;
			transition: 0.5s;

			&:hover {
				color: #675239;
				background: url(../../assets/img/fonttitle3.png) no-repeat bottom;
			}
		}
	}

	.hotViewTitle {
		padding-bottom: 30px;

		h3 {
			font-size: 19px;
			font-weight: bold;
			color: #343434;
			line-height: 29px;
		}

		h4 {
			font-size: 15px;
			line-height: 25px;
		}
	}

	.hotViewOne {
		height: 494px;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		-webkit-animation-duration: 1.5s;
		animation-duration: 1.5s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		cursor: pointer;
		position: relative;

		&:hover {
			img {
				transform: scale(1.1);
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			transition: 2s;
			width: 100%;
			height: 100%;
		}

		.hotViewDIV {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 10px 15px;
			min-height: 200px;

			h1 {
				font-size: 28px;
				color: #d13434;
				line-height: 39px;
			}

			h6 {
				font-size: 26px;
				color: #FFFFFF;
				line-height: 36px;
				margin-bottom: 5px;
			}

			p {
				font-size: 15px;
				line-height: 24px;
				color: #FFFFFF;
			}
		}

		.hotViewh11 {
			color: #d5571e !important;
		}

		.hotViewh12 {
			color: #ecc20c !important;
		}

		.hotViewh13 {
			color: #a8a8a8 !important;
		}
	}

	.toEncounterBOX {
		.toEncounter {
			.toEncounterone {
				width: 100%;
				height: 798px;
				position: relative;
				overflow: hidden;

				&:hover {
					.toEncounterMsg {
						top: 0px !important;
					}

					.toEncounterObdiv {
						opacity: 1 !important;
					}

				}

				.toEncounterMsg {
					position: absolute;
					transition: 1s;
					background-color: rgba(0, 0, 0, 0.5);
					top: -528px;
					left: 0;
					width: 100%;
					height: 528px;
					text-align: center;
					color: #FFFFFF;

					h2 {
						font-size: 28px;
						font-weight: bold;
						padding-top: 120px;
						padding-bottom: 30px;
					}

					p {
						font-size: 18px;
						line-height: 32px;
						font-family: STKaiti;
						position: relative;
						cursor: pointer;

						&:hover::after {
							opacity: 1;
						}

						&::after {
							content: '';
							width: 124px;
							height: 4px;
							background-color: #ffb114;
							position: absolute;
							bottom: 0;
							left: 50%;
							margin-left: -62px;
							opacity: 0;
						}

					}

					.toEncounterbtn {
						position: absolute;
						bottom: 25px;
						left: 50%;
						margin-left: -42px;
						width: 94px;
						height: 22px;
						font-size: 14px;
						line-height: 22px;

						text-align: center;
						color: #d5b573;
						background: url(../../assets/img/fonttitle2.png) no-repeat;
						background-size: 100%;
					}
				}

				.imgbox {
					width: 100%;
					height: 528px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

				}

				.toEncounterObtm {
					width: 100%;
					background-color: #aa815e;
					height: 270px;
					text-align: center;
					color: #FFFFFF;
					position: relative;

					h2 {
						// text-align: center;
						font-size: 27px;
						padding: 25px 0;
						font-weight: 500;
					}

					p {
						font-size: 17px;
						line-height: 28px;
					}

					.toEncounterObdiv {
						position: absolute;
						top: 10px;
						left: 2%;
						background-color: #FFFFFF;
						width: 96%;
						height: 240px;
						padding-top: 10px;
						opacity: 0;
						transition: 1s;

						h2 {
							font-size: 40px;
							color: #000000;
							font-family: NSimSun;
							padding: 0;
							line-height: 64px;
							position: relative;
							z-index: 0;

							&::after {
								content: '';
								position: absolute;
								margin-left: -62px;
								bottom: 10px;
								left: 50%;
								width: 125px;
								height: 12px;
								background-color: #ffb114;
								z-index: -1;
							}
						}

						h3 {
							font-family: xygpbt;
							font-size: 22px;
							color: #b7b7b7;
						}

						p {
							padding-top: 30px;
							margin: 0 10%;
							font-family: STFangsong;
							font-size: 20px;
							color: #676767;
							line-height: 30px;

						}
					}
				}
			}
		}
	}

	.touristGuideBOX {
		padding-bottom: 500px;
	}

	.touristGuideB {
		.touristGuide {
			cursor: pointer;
			-webkit-animation-duration: 2s;
			animation-duration: 2s;
			-webkit-animation-fill-mode: both;
			animation-fill-mode: both;
			margin: 0 auto;
			width: 219px;

			// height: 223px;
			img {
				width: 100%;
				height: auto;
				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;

				&:hover {
					-webkit-animation-name: headShake;
					animation-name: headShake
				}
			}

			p {
				margin-top: 20px;
				font-size: 22px;
				color: #aa815e;
				text-align: center;
				font-weight: bold;
			}
		}
	}

	.flex {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.bannerbox {
		width: 100%;
		height: 980px;
		position: relative;
		z-index: 0;

		.carouselDot {
			position: absolute;
			bottom: 40px;
			left: 0;
			width: 100%;
			height: 2px;
			border-bottom: 2px dotted #FFFFFF;
			z-index: 9;
			display: flex;
			justify-content: space-around;

			.carouselDotO {
				width: 80px;
				height: 6px;
				background-color: #FFFFFF;
				margin: 0 30px;
				position: relative;

				i {
					font-size: 40px;
					position: absolute;
					top: -55px;
					left: 50%;
					margin-left: -20px;
				}

				p {
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 50px;
				}
			}
		}

		::v-deep .el-carousel__container {
			width: 100%;
			height: 980px;
		}

		::v-deep .el-carousel__indicators {
			display: none;
		}

		::v-deep .el-carousel__arrow {
			background-color: #ffffff;
			box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
		}

		::v-deep .el-icon-arrow-right {
			color: #000;
		}

		::v-deep .el-icon-arrow-left {
			color: #000;
		}

		.ratio-img {
			::v-deep .el-carousel__container {
				width: 100%;
				height: 980px;
			}

			::v-deep .el-carousel__arrow {
				display: none;
			}

			//  .is-active{
			// 	::v-deep .el-carousel__button{
			// 		    background: #f0c8a0!important;
			// 	}
			// }
			::v-deep .el-carousel__button {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				border: 1px solid #f0c8a0;
				opacity: 1;
				background: transparent;
				margin: 0 10px;
			}

			::v-deep .el-carousel__indicators--horizontal {
				left: 85%;
			}

			::v-deep .el-carousel__indicator.is-active button {
				background: #f0c8a0 !important;
			}
		}
	}

	.slider-selection-card {
		box-shadow: 1px 3px 5px #f1f1f1;
	}
</style>
