<template>
	<div class="three">
		<nav-box></nav-box>
		<div>
			<div id="app" class="main">
				<div class="site_banner">
					<img src="../../assets/img/xishan-bg2.png">
				</div>

				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="20" :xs="24">
						<Title title="酒店预定"></Title>
						
						<div class="">
							<div class="ticket_list">
								<ul>
									<li v-for="(item,index) in List" :key='index'>
										<div class="ticket_box">
											<dl>
												<dt><a href="javascript:void(0);" class="tran_scale"><img data-ratio="0.56418"
															onerror="lod(this)"
															:src="item.img"
															class="ratio-img" style="height: 189px;"></a></dt>
												<dd>
													<h3 class="fnt_24"><a href="javascript:void(0);">{{item.name}}</a></h3>
													<div class="hptel_p01">
														<span style="width: auto; ">酒店配套：</span>
														<span style="margin-right: 12px;" v-for="(tag,t_index) in item.tags" :key="t_index">{{tag}}</span>
														
													</div>
													<div class="hptel_p01">
														<p>地址：{{item.address}}</p>
														<p>电话：{{item.contact}}</p>
													</div>
												</dd>
											</dl>
											<div class="ticket_re">
												<span style="display: flex; align-items: center;">
													<span style="text-decoration: line-through; font-size: 14px; color: gray;">￥{{item.min_price_close}}/起</span>
													<span class="fnt_18">￥<b class="fnt_24">{{item.min_price}}起</b></span>
												</span> 
												<a target="_blank" :href="item.url" class="onecard_abtn">立即预订</a></div>
										</div>
									</li>
								
								</ul>
							</div>
						</div>
					</el-col>
				</el-row>
				
				
			</div>
		</div>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import NavBox from '@/components/navBox.vue'
	export default {
		name: 'three',
		components: {
			NavBox
		},
		data(){
			return{
				List:[]
			}
		},
		computed: {

		},
		created() {
			this.getList()
		},
		methods:{
			getList(){
				this.List=[
					{
						name:'鄂州鄂钢宾馆',
						desc:'鄂州鄂钢宾馆位于武昌大道，地段优越，交通便利。房间设施齐全，卫生干净，温馨舒适，是您理想的入住场所',
						url:'https://hotels.ctrip.com/hotels/detail/?hotelId=1250916',
						address:'中国，湖北，鄂州，鄂城区，武昌大道217号',
						contact:'0711-3233201',
						tags:['篮球场','网球场','免费停车','茶室'],
						img:require('@/assets/hotel/bg1.jpeg'),
						min_price:'140',
						min_price_close:'158',
					},
					{
						name:'鄂州天龙大酒店',
						desc:'酒店，地理位置优越，交通十分便利，酒店设施齐全，为宾客带来各类人性化的贴心服务，用真诚和热情欢迎您的下榻光临。',
						url:'https://hotels.ctrip.com/hotels/detail/?hotelId=1409490',
						address:'中国，湖北，鄂州，鄂城区，武昌大道210号',
						contact:'0711-3262188',
						tags:['行李寄存','闪住'],
						img:require('@/assets/hotel/bg2.jpeg'),
						min_price:'83',
						min_price_close:'143',
					},
					{
						name:'鄂州锦江精品酒店',
						desc:'对于想要捕捉鄂州城市风采的旅客来说，鄂州锦江精品酒店是一个理想的选择。酒店地理位置优越，驾车至鄂州站仅需5km 。包括鄂州西山风景区和莲花山都在酒店周边，对于入住旅客想在该地区畅游会很方便。优美的环境，再搭配上细致周到的服务，酒店的休闲区定能满足您的品质需求。',
						url:'https://hotels.ctrip.com/hotels/detail/?hotelId=6728023',
						address:'中国，湖北，鄂州，鄂城区，江碧路',
						contact:'13177387566',
						tags:['免费停车','闪住'],
						img:require('@/assets/hotel/bg3.jpeg'),
						min_price:'118',
						min_price_close:'138',
					},
					{
						name:'鄂州飞鹅假日酒店',
						desc:'鄂州飞鹅假日酒店邻近明堂市场、鄂州市中心医院、银泰百货、鄂州博物馆、观音阁公园等地，地理位置优越，交通便捷。鄂州飞鹅假日酒店拥有多种房型供客人选择，房间内一次性洗漱用品、独立卫生间等基本配套设施齐全，床上用品一客一换，干净卫生，舒适温馨，是您商务出行、家庭旅游的理想选择。',
						url:'https://hotels.ctrip.com/hotels/detail/?hotelId=4693927',
						address:'中国，湖北，鄂州，鄂城区，文星路17号',
						contact:'0711-3227610',
						tags:['免费停车','商务中心','闪住'],
						img:require('@/assets/hotel/bg4.jpeg'),
						min_price:'123',
						min_price_close:'172',
					},
					{
						name:'鄂州南悦大酒店',
						desc:'鄂州南悦大酒店位于鄂州市武昌大道金融、商务核心地段，地理位置优越，交通便利，购物方便。酒店距离鄂州著名景区西山吴王避暑宫、江滩公园、南浦路步行街、夜市好吃街、银兴国际影城，步行皆约10分钟可达。离鄂州市市政府、顺丰机场、鄂州著名的莲花山风景区，有公交车可直达，大约车程十几分钟到。',
						url:'https://hotels.ctrip.com/hotels/detail/?hotelId=1523678',
						address:'中国，湖北，鄂州，鄂城区，武昌大道中段302-3号',
						contact:'0711-3289996',
						tags:['免费停车','行李寄存','快速入住退房'],
						img:require('@/assets/hotel/bg5.jpeg'),
						min_price:'168',
						min_price_close:'298',
					}
				]
			}
			
			
		}
	}
</script>
<style lang="less" scoped>

</style>
