<template>
	<div class="four bg">
		<nav-box></nav-box>
		<div class="site_banner">
			<img src="../../assets/img/xishan-bg1.png">
		</div>
		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<Title title="游园景点"></Title>
				<div class="main site_b70">

					<div class=" padd_top50">

						<div class="storyUl ajLoad3">
							<ul v-for="(item,index) in articleList" style="">
								<li class="public_li " v-if="item.num%2==1">
									<div class="storyUl_l storyUl_l1">
										<h3 class="fnt_36">{{item.artTitle}}</h3>
										<div class="storyUl_lDiv" v-html='item.artContent'></div>
									</div>
									<div class="storyUl_r">
										<img class="storyUl_rimg" style="width:100%" :src="item.artPicUrl" />
									</div>
								</li>
								<li class="public_li public_li_two" v-if="item.num%2==0">
									<div class="storyUl_l storyUl_l2">
										<h3 class="fnt_36">{{item.artTitle}}</h3>
										<div class="storyUl_lDiv" v-html='item.artContent'></div>
									</div>
									<div class="storyUl_r">
										<img class="storyUl_rimg" style="width:100%" :src="item.artPicUrl" />
									</div>
								</li>
							</ul>
						</div>　　　　　　　　　　　　　　　　　　　　　　
					</div>
				</div>
			</el-col>
		</el-row>
			<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import NavBox from '@/components/navBox.vue'
	export default {
		name: 'four',
		components: {
			NavBox
		},
		data() {
			return {
				articleList: [],
				nowItem: {}
			}
		},
		computed: {

		},
		created() {
			this.getList()
		},
		methods: {
			haveItem(item) {
				this.nowItem = item
			},
			//接口
			getList() {
				let data = {
					clientId: 'weixinManagement',
					ticNumber: ''
				}
				this.$http.getArticles(data).then(res => {
					let data = res.data.data
					if (data && data.items) {
						let articleList = data.items;
						this.articleList = articleList;
						this.articleList.forEach((item, index) => {
							item.num = Number(index + (1 * 1))
						})
						console.log(this.articleList)
					}
				})

			},
		}
	}
</script>
<style lang="less">
	.bg {
		// background-color: #faf2e6;
	}

	.four {
		::v-deep .el-carousel__indicators {
			display: none;
		}

		::v-deep .el-carousel__arrow {
			background-color: #ffffff;
			box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
		}

		::v-deep .el-icon-arrow-right {
			color: #000;
		}

		::v-deep .el-icon-arrow-left {
			color: #000;
		}

		::v-deep .el-carousel {
			z-index: 110000
		}
	}


	.public_li {
		margin-bottom: 20px !important;

		&:hover {
			.storyUl_rimg {
				transform: scale(1.1);
			}
		}

		.storyUl_l {
			width: 43%;
			background-color: #f2f1f1;
			height: 420px;

		}

		.storyUl_r {
			width: 56%;
			padding: 0;
			height: 420px;
			overflow: hidden;

			img {
				transition: 2s;
				width: 100%;
				height: 100%;
			}
		}
	}

	.storyUl_lDiv {
		p {
			text-align: justify;
			text-indent: 2em;
		}
	}
	.storyUl_l1{
		position: relative;
		&::after{
			content: '';
			position: absolute;
			top:20px;
			right: 20px;
			width: 40px;
			height: 30px;
			border-top: 1px solid #a27b2c;
			border-right:1px solid #a27b2c;
		}
	}
	.storyUl_l2{
		position: relative;
		&::after{
			content: '';
			position: absolute;
			top:20px;
			left: 20px;
			width: 40px;
			height: 30px;
			border-top: 1px solid #a27b2c;
			border-left:1px solid #a27b2c;
		}
	}
</style>
