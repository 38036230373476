<template>
	<div>
		<nav-box></nav-box>
		<div class="main">
			<div class="site_banner">
				<img src="../../assets/img/xishan-bg1.png">
				<!-- <div class="blank_20"></div> -->
			</div>
			
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<Title title="游园攻略"></Title>
					
					<div class="">
						<div class="travel_list">
							<ul>
								<ul>
									<li v-for="(item,index) in items" :key="index" :style="{'border':index==items.length-1?0:''}">
										<dl>
											<dt><a :href="'/newDetail?id='+item.id" target="_blank" class="tran_scale">
											<img data-ratio="0.56435" onerror="lod(this)" :src="'https://www.ezhouxishan.com/hdstorApi/web'+item.coverPic"
														class="ratio-img" style="height: 227px;"></a></dt>
											<dd>
												<h3 class="fnt_24"><a :href="'/newDetail?id='+item.id"
														target="_blank">{{item.title}}</a></h3>
												<div class="p_time">{{item.updateTime}}</div>
												<p class="subFont">{{item.contentValidity}}</p>
												<div class="travel_more fnt_16"><a :href="'/newDetail?id='+item.id"
														target="_blank">了解更多</a></div>
											</dd>
										</dl>
									</li>
					
								</ul>
							</ul>
						</div>
					</div>
					
				</el-col>
			</el-row>
			
			<div id="" class="padding_bottom"></div>


		</div>
		<footerBox />
	</div>
</template>

<script>
	import{getArticleList , getArticleDetail} from '@/api/content.js'
	export default{
		data() {
			return {
				items: []
			}
		},
		created() {
			this.getData()
		},
		methods: {
			getData() {
				let data={
					categoryId:15
				}
				getArticleList(data).then(res=>{
					if(res.data && res.data.data && res.data.data.records){
						this.items = res.data.data.records
					}
				})
			}
			
		},
	}
</script>

<style>
	.travel_list dd p {
		line-height: 26px;
		height: 52px;
	}
	.travel_more a{
		color: #939393;
		border-bottom: 1px solid #939393;
	}
	.subFont{
		letter-spacing: 0.8px;
	}
</style>
