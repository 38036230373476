<template>
	<div class="three">
		<nav-box></nav-box>
		<div>
			<div id="app" class="main">
				<div class="site_banner">
					<img src="../../assets/img/xishan-bg4.jpg">
				</div>
				<el-row type="flex" class="row-bg" justify="center" v-if="ticketData">
					<el-col :span="20" :xs="24">
						<Title title="门票预定"></Title>
						<div class="meal_dcont  cart_reserve">
							<div class="pub_cont">
								<div class="pub_le">
									<div class="pub_01">
										<!---->
										<div class="iteam_box iteam_box01" style="margin-top: 10px;">
											<dl>
												<dt><img onerror="lod(this)"
														:src="ticketData.ticPicture"
														class="ratio-img" style="height: 159px;"></dt>
												<dd>
													<h1 class="fnt_24">{{ticketData.ticName}}
														<!---->
													</h1>
													<!---->
												</dd>
											</dl>
											<div class="calendar_food" style="display: flex;">
												<!---->
												<div style="display: flex;">
													<div
														class="el-date-editor el-input el-input--prefix el-input--suffix el-date-editor--date">
														<!----><input type="date" readonly="readonly" autocomplete="off" name="" :value="date"
															placeholder="选择日期" class="el-input__inner"><span
															class="el-input__prefix"><i class="el-input__icon el-icon-date"></i>
															<!---->
														</span><span class="el-input__suffix"><span
																class="el-input__suffix-inner"><i class="el-input__icon"></i>
																<!---->
																<!---->
																<!---->
																<!---->
															</span>
															<!---->
														</span>
														<!---->
														<!---->
													</div>
													<!---->
												</div>
												<!---->
												<!---->
												<div
													style="display: flex; background-color: rgb(255, 255, 255); justify-content: center; align-items: center; padding-left: 20px; padding-right: 20px; margin-left: 12px;">
													<span>预订数量</span>
													<div id="tab" style="margin-left: 12px;">
														<input type="button" value="-" class="addbtn" @click="minus"> 
														<input type="text" readonly="readonly" v-model="ticNum" class="text_box"> 
														<input type="button" value="+" class="addbtn " @click="plus">
													</div>
												</div>
											</div>
											<!---->
										</div>
									</div>
									<div class="pub_02">
										<div class="pub_form">
											<h3 class="fnt_24">预订人信息</h3>
											<div class="shopping_form">
												<ul>
													<li class="li01">
														<div class="form_w">
															<div class="form_input">
																<input placeholder="预订人姓名" type="text" maxlength="32">
															</div>
														</div>
													</li>
													<li class="li02 ">
														<div class="form_w">
															<div class="form_input">
																<input placeholder="预订人手机号码（便于接收通知）"type="text">
															</div>
														</div>
													</li>
													<!---->
												</ul>
											</div>
										</div>
										<div class="pub_form">
											<div class="shopping_form">
												<h1>门票实名信息：{{ticketData.ticName}}</h1>
												<ul class="ticket_person_info">
													<li class="li01">
														<div class="form_w">
															<h4>姓名</h4>
															<div class="form_input"><input placeholder="姓名" type="text"
																	class="name"></div>
														</div>
													</li>
													<li class="li02 phoneSelect homeSelect">
														<div class="form_w">
															<h4>证件类型</h4>
															<div class=" clearfix">
																<dl class="select_c">
																	<dt><i>证件类型</i>
																		<p class="certType">
																			身份证</p>
																	</dt>
																</dl>
															</div>
														</div>
													</li>
													<li class="li01">
														<div class="form_w">
															<h4>证件号码</h4>
															<div class="form_input"><input placeholder="证件号码" type="text"
																	class="certNo"></div>
														</div>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="pub_03">
										<div class="cart_edit">
											<!---->
											<!---->
											<!---->
											<div class="point_div01 cart_edit_01">
												<h1 class="fnt_24">门票预订温馨提示</h1>
												<div class="edit">
													<p>门票当日一次有效，不能重复使用;<br>请妥善保管好预订成功短信，入园时直接打开短信链接，打开二维码刷码入园;<br>如检票、参观等场所客流繁忙时，请按序排队进入或参观;
													</p>
												</div>
											</div>
											<!---->
											<!---->
										</div>
									</div>
								</div>
								<div id="feiyong" class="pub_re">
									<div class="pub_detailed">
										<h1 class="fnt_18">费用明细</h1>
										<div class="cost">
											<div class="cost_insert">
												<div class="cost_time">
													<h3 class="fnt_24">{{ticketData.ticName}}
														<!---->
													</h3>
													<div class="detailed_ul">
														<!---->
														<ul>
															<li>
																<p>游玩日期 <em>{{date}}</em></p> <span>￥0 x 1</span>
															</li>
														</ul>
														<!---->
														<!---->
													</div>
													<!---->
												</div>
											</div>
										</div>
										<div class="cost_total fnt_18">
											<p>总价</p> <span class="price">￥</span>
										</div>
										<div class="cost_btn"><a href="javascript:void();" class="cost_btn02">支付</a></div>
									</div>
								</div>
							</div>
						</div>
					</el-col>	
				</el-row>
				

			</div>
		</div>
		<footerBox />
	</div>
</template>

<script>
	import{getTicketDetail ,getConsume ,getTraveler} from '@/api/public.js'
	import {
		ymd,
		getWeek,
		getStamp
	} from '@/utils/format.js'
	import NavBox from '@/components/navBox.vue'
	export default {
		name: 'three',
		components: {
			NavBox
		},
		data() {
			return {
				batchIndex: 0,
				timeSlot: "",
				appointment: 0,
				batch: "1",
				batchList: [],
				originBatchList: [],
				commentTotal: 0,
				commentList: [],
				receiverName: "",
				receiverPhone: "",
				
				id:0,
				date: ymd(),
				ticketData: null,
				ticNum: 1,
				travelInfoList: [],
				checkIds: [],
				peopleIndex: 0,
				realConfig: 0,
				consume: null, //余额
				
				user:{
					userId:1
				}
			}
		},
		computed: {
			dateWeek() {
				return this.date ? getWeek(this.date) : ''
			},
			totalPrice() {
				let totalPrice = 0;
				if (this.ticketData) {
					let ticPrice = this.ticketData.ticRetailPrice;
					totalPrice = ticPrice * this.ticNum;
				}
				return totalPrice
			},
			travelNum() {
				return this.realConfig == 3 || this.realConfig == 5 ? 1 : this.ticNum;
			}
		},
		created() {
			this.id =this.$route.query.id;
			this.getData();
		},
		methods: {
			getData(){
				this.getTicket();
				this.getConsume();
				this.getTraveler();
			},
			getTicket() {
				getTicketDetail({id: this.id,date: this.date}).then(res => {
					if (res.data.code == 200) {
						this.ticketData = res.data.data
					}
				})
			},
			getConsume() {
				getConsume({wxUserId: this.user.userId}).then(res => {
					if (res.data.code == 200) {
						this.consume = res.data.data.items;
					}
				})
			},
			getTraveler() {
				getTraveler({limit: 20,page: 1,traUserId: this.user.userId}).then(res => {
					let realConfig = this.realConfig || res.data.data.realConfig;
					this.realConfig = realConfig;
				})
			},
			addOrder(){
				this.$router.push({name:'ticketReserve' ,query:{id:this.id}});
			},
			minus(){
				if(this.ticNum==1){
					return false;
				}else{
					this.ticNum--;
				}
			},
			plus(){
				this.ticNum ++;
			}
		}
	}
</script>
<style lang="less" scoped>

</style>
