<template>
	<div id="">
		<div class="orderTopTitle flex">
			<p style="width: 25%;">场地信息</p>
			<p style="width: 25%;">预约场次</p>
			<p style="width: 25%;">预约日期</p>
			<p style="width: 25%;">预约信息</p>
		</div>
		<ul id="" class="orderul">
			<li class="orderLi" v-for="(item,index) in items" :key="index">
				<div id="" class="ordertopinfo flex justify_between align_start">
					<div id="" class="lefttopinfo">
						<h4>订单编号：{{item.ordOrderSn}}</h4>
					</div>
					<div id="" class="righttopinfo">
						预约时间: {{item.orderTime}}
					</div>
				</div>
				<div id="" class="flex justify_between goodinfoBox">
					<div id="" style="width: 20%;" class="">
						<h4 style="font-size: 22px;">{{item.mesSiteName}}</h4>
					</div>
					<div id="" class="goodNum">
						{{item.mesSesName}}
					</div>
					<div id="" class="goodNum">
						{{item.mesYMDTime}}
					</div>
					<!-- <div id="" style="width: 15%;" class="goodNum">
						{{item.startTime}}
					</div> -->
					<div id="" style="width: 15%;" class="goodHndle flex_column flex">
						{{item.mesName}} {{item.mesCellPhone}}
					</div>
				</div>
			</li>
		</ul>
	
	</div>
</template>

<script>
	export default{
		props: {
			items: {
				type: Array,
				default: function(){
					return []
				}
			},
		},
	}
</script>

<style>
</style>
