import { render, staticRenderFns } from "./five.vue?vue&type=template&id=58821f61&scoped=true&"
import script from "./five.vue?vue&type=script&lang=js&"
export * from "./five.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58821f61",
  null
  
)

export default component.exports