<template>
	<div class="five">
		<nav-box></nav-box>
		<div class="main site_b70">
			<div class="site_banner">
				<img src="../../assets/img/xishan-bg4.jpg">
			</div>
			<el-row type="flex" class="row-bg" justify="center">
				<el-col :span="20" :xs="24">
					<Title title="西山服务"></Title>
					
					<div class=" padd_top50">
						<div class="faq-part">
							<ul class="fixed">
								<li  :class="ItemIn==1?'on':''"  >
									<div class="faq-t active">
										<h2 class="fnt_24"><em><img
													src="https://gboss.wtown.com/pc/images/faq_dot01.png"></em>请问西山景区的门票价格是多少
										</h2>
										<i @click='choseItem(0)' v-if='ItemIn==1' class="el-icon-minus"></i>
										<i @click='choseItem(1)' v-else class=" el-icon-plus"></i>
									</div>
									<div class="faq-c" :style="{'display':ItemIn==1?'block':'none'}">
										<div class="edit_con_original">
											<p>答：西山景区门票价格20元/人。</p>
										</div>
									</div>
								</li>
								<li :class="ItemIn==2?'on':''"  >
									<div class="faq-t">
										<h2 class="fnt_24"><em><img
													src="https://gboss.wtown.com/pc/images/faq_dot01.png"></em>纯游玩进入景区需要带身份证吗？
										</h2>
									<i @click='choseItem(0)' v-if='ItemIn==2' class="el-icon-minus"></i>
									<i @click='choseItem(2)' v-else class=" el-icon-plus"></i>
									</div>
									<div class="faq-c" :style="{'display':ItemIn==2?'block':'none'}">
										<div class="edit_con_original">
											<p>
												答：现场扫码入园无需身份证
											</p>
										</div>
									</div>
								</li>
								<li :class="ItemIn==3?'on':''"  >
									<div class="faq-t">
										<h2 class="fnt_24"><em><img
													src="https://gboss.wtown.com/pc/images/faq_dot01.png"></em>避暑宫秀园和西山景区是一起的吗？
										</h2>
										<i @click='choseItem(0)' v-if='ItemIn==3' class="el-icon-minus"></i>
										<i @click='choseItem(3)' v-else class=" el-icon-plus"></i>
									</div>
									<div class="faq-c" :style="{'display':ItemIn==3?'block':'none'}">
										<div class="edit_con_original">
											答：避暑宫秀园景区都在西山景区范围内。
										</div>
									</div>
								</li>
								<li :class="ItemIn==4?'on':''"  >
									<div class="faq-t">
										<h2 class="fnt_24"><em><img
													src="https://gboss.wtown.com/pc/images/faq_dot01.png"></em>孩子和老人是否有优惠或免票？
										</h2>
										<i @click='choseItem(0)' v-if='ItemIn==4' class="el-icon-minus"></i>
										<i @click='choseItem(4)' v-else class=" el-icon-plus"></i>
									</div>
									<div class="faq-c">
										<div class="edit_con_original" :style="{'display':ItemIn==4?'block':'none'}">
											<p>
												答：西山景区优惠票：身高1.2米—1.5米（含）的儿童；中、小学生凭学生证；<span>16周岁以下凭身份证；</span>60周岁以上老年人凭身份证或优待卡；现役军人凭有效证件；国家消防救援人员（国家综合性消防救援队伍消防员证、国家综合性消防救援队伍干部证、国家综合性消防救援队伍学员证）&nbsp;
												&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
												&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
											</p>
											<p>
												&nbsp; &nbsp; &nbsp;
												西山景区免票：身高1.2米（含）以下儿童；离休人员凭国务院、中央军委制发的离休干部荣誉证；残疾人凭中国残联制发《残疾人证》；残疾军人凭民政部制发《残疾军人证》；中华人民共和国伤残人民警察证；中华人民共和国伤残国家机关工作人员证；中华人民共和国伤残民兵民工证<span>；</span><span>记者凭新闻出版总署颁发的记者证</span>。
											</p>
										</div>
									</div>
								</li>
								<li :class="ItemIn==5?'on':''"  >
									<div class="faq-t">
										<h2 class="fnt_24"><em><img
													src="https://gboss.wtown.com/pc/images/faq_dot01.png"></em>住宿在景区是否可以免门票？
										</h2>
										<i @click='choseItem(0)' v-if='ItemIn==5' class="el-icon-minus"></i>
										<i @click='choseItem(5)' v-else class=" el-icon-plus"></i>
									</div>
									<div class="faq-c" :style="{'display':ItemIn==5?'block':'none'}">
										<div class="edit_con_original">
											答：景区的门票还是要购买的，因为景区内的酒店只是我们的配套设施，您除了住宿外，还有很多景点可以游玩。但是根据景区的销售政策，部分酒店是赠送门票的，具体您可以拨打景区咨询预定电话3209622进行详细咨询。
										</div>
									</div>
								</li>
								<li :class="ItemIn==6?'on':''"  >
									<div class="faq-t">
										<h2 class="fnt_24"><em><img
													src="https://gboss.wtown.com/pc/images/faq_dot01.png"></em>景区里面用餐或是买东西可以开发票吗？
										</h2>
										<i @click='choseItem(0)' v-if='ItemIn==6' class="el-icon-minus"></i>
										<i @click='choseItem(6)' v-else class=" el-icon-plus"></i>
									</div>
									<div class="faq-c " :style="{'display':ItemIn==6?'block':'none','height':ItemIn==6?'auto':'0'}">
										<div class="edit_con_original">
											答：可以，用餐点可以到各大型餐厅（烧肉馆、纳兰轩餐厅、西山烤鸭王、文昌阁中餐厅等）有发票的经营点。但商铺需要在购物处开具“发票开具单”后。到游客中心大前台开具发票。
										</div>
									</div>
								</li>
								
							</ul>
						</div>
					</div>
				</el-col>
			</el-row>
			
		</div>
		<footerBox />
	</div>
</template>

<script>
	import NavBox from '@/components/navBox.vue'
	export default {
		name: 'five',
		components: {
			NavBox
		},
		data(){
			return{
				ItemIn:1
			}
		},
		computed: {

		},
		created() {

		},
		methods:{
			choseItem(i){
				this.ItemIn=i
			}
		}
	}
</script>
<style lang="less" scoped>

</style>
