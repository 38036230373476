<template>
	<div id="">
		<nav-box></nav-box>
		<div id="" class="topimg">
			<img src="../../assets/img/xishan-bg2.png">
		</div>

		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<h2 class="H2title">
					<p>XISHAN TRAFFIC</p>
					<span id="">
						交通
					</span>
					<div id="" class="guidtitle">
						<a href="/">首页></a><a href="/">出行西山></a><a href="/">交通</a>
					</div>
				</h2>
				<div id="" class="timeBox">
					<h3>开放时间</h3>
					<div id="" class=" flex">
						<div id="" class="timgcontent">
							<div id="" class="flex timgcontentdiv" style="align-items: flex-start;">
								<h4></h4>
								<div id="">
									<h5>上午</h5>
									<div id="" class="flex">
										<div id="" class="timin">
											<h6>05:30</h6>
											<p>开始时间</p>
										</div>
<!-- 										<h5 class="borderNo">~</h5>
										<div id="" class="timin">
											<h6>18:00</h6>
											<p>结束时间</p>
										</div> -->
									</div>

								</div>
							</div>
							<div id="" class="flex timgcontentdiv borderNo" style="align-items: flex-start;">
								<h4></h4>
								<div id="">
									<h5>下午</h5>
									<div id="" class="flex">
										<!-- <div id="" class="timin">
											<h6>08:00</h6>
											<p>开始时间</p>
										</div>
										<h5 class="borderNo">~</h5> -->
										<div id="" class="timin">
											<h6>18:00</h6>
											<p>结束时间</p>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div id="" class="timeimgbox">
							<img src="../../assets/img/33eca213580db4e37367d8ad3ec5b26d.gif">
						</div>
					</div>
				</div>
				<div id="" class="timeBox">
					<h3>交通指南</h3>
					<div id="" class="trafficimgbox" >
						<img src="../../assets/image/time.png">
						<img src="../../assets/image/guide-map.jpg">
					</div>
					<div id="" class="trafficbox">
						<div id="" class="flex " style="justify-content: flex-start;align-items: flex-start;">
							<img src="../../images/img/Combined.png">
							<h1>火车:</h1>
							<p>从武汉或武昌站乘坐火车至鄂州站，打车或乘坐11路车至市二医院，即可到达;</p>
						</div>
						<div id="" class="flex " style="justify-content: flex-start;align-items: flex-start;">
							<img src="../../images/img/Combined3.png">
							<h1>汽车:</h1>
							<p>在武昌长途客运站乘坐“武汉―鄂州”长途客车，可以直接坐到鄂州汽车客运站;
							</p>
						</div>
						<div id="" class="flex " style="justify-content: flex-start;align-items: flex-start;">
							<img src="../../images/img/Combined2.png">
							<h1>自驾:</h1>
							<p>由武鄂高速从武汉至鄂州西互通立交桥下高速，左转进入旭光大道行进4公里，右拐至樊 川大道行驶2公里，再右转至武昌大道行驶4公里，左转进入公园路行驶300米即到景区。</p>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>

		<footerBox />
	</div>
</template>

<script>
	export default {

		components: {

		},
		data() {
			return {

			}
		}
	}
</script>

<style lang="less">
	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.topimg {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;

		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;

			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}

		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;

		}

		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;

		}

		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../../assets/img/fontbg.png) no-repeat bottom;

		}
	}

	.timeBox {

		padding: 102px 0 135px;

		h3 {
			margin-bottom: 27px;
			height: 56px;
			font-size: 40px;
			font-family: STSongti-SC-Regular, STSongti-SC;
			font-weight: 400;
			color: #3E3E3E;
			line-height: 56px;
		}

		.timeimgbox {
			width: 70%;
			height: 584px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.timgcontent {
			width: 30%;
			background-color: #F6F3EF;

			.timgcontentdiv {
				border-bottom: 1px dashed #979797;
				padding: 70px 0;
				justify-content: flex-start;

				h4 {
					padding: 0 30px;
					height: 45px;
					font-size: 32px;
					font-family: BodoniSvtyTwoSCITCTT-Book, BodoniSvtyTwoSCITCTT;
					font-weight: normal;
					color: #3E3E3E;
					line-height: 45px;
					letter-spacing: 2px;
				}

				h5 {
					margin-bottom: 20px;
					border-top: 1px solid #3E3E3E;
					border-bottom: 1px solid #3E3E3E;
					height: 55px;
					font-size: 32px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #3E3E3E;
					line-height: 55px;
					letter-spacing: 2px;
				}

				h6 {

					height: 45px;
					font-size: 32px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #3E3E3E;
					line-height: 45px;
					letter-spacing: 2px;
				}

				p {

					height: 28px;
					font-size: 20px;
					font-family: BodoniSvtyTwoSCITCTT-Book, BodoniSvtyTwoSCITCTT;
					font-weight: normal;
					color: #3E3E3E;
					line-height: 28px;
					letter-spacing: 1px;
				}
			}
		}
	}

	.borderNo {
		border: 0 !important;
	}

	.trafficimgbox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		img {
			height: 400px;
			width: auto;
		}
	}

	.trafficbox {
		padding: 30px;

		div {
			padding: 20px 0;
			position: relative;
			&:hover{
				img{
					top:10px;
				}
			}
			img {
				position: absolute;
				top:22px;
				left: 10px;
				width: 48px;
				height: 48px;
				transition: 0.5s;
			}

			h1 {
				margin: 0 20px 0 80px;
				height: 48px;
				font-size: 24px;
				font-family: STSongti-SC-Black, STSongti-SC;
				font-weight: 900;
				color: #3E3E3E;
				line-height:48px;
			}
			p{
				width: 87%;
				font-size: 24px;
				font-family: STSongti-SC-Regular, STSongti-SC;
				font-weight: 400;
				color: #3E3E3E;
				line-height: 48px;
			}
		}
	}
</style>
