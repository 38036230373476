<template>
  <div class="two">
    <nav-box></nav-box>
	<div style="height:50px"></div>
	<iframe style="width: 100%;height: 100vh;" src="https://map.whzsh.com/"></iframe>
   <footerBox />
  </div>
</template>

<script>
import NavBox from '@/components/navBox.vue'
export default {
  name: 'two',
  components: {
    NavBox
  },
  computed:{
    
  },
  created(){
    
  }
}
</script>
<style lang="less" scoped>

</style>