<template>
	<div id="" class="hotbg">
		<nav-box></nav-box>
		<div id="" class="topimg">
			<img src="../../assets/img/topImg.png">
		</div>

		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<h2 class="H2title">
					<p>XISHAN SPECIALTY</p>
					<span id="">
						西山特产
					</span>
					<div id="" class="guidtitle">
						<a href="/">首页></a><a href="/">出行西山></a><a href="/">西山特产</a>
					</div>
				</h2>
			<div id="" class="food">
				<el-row :gutter="30" type="flex" class="row-bg" justify="space-between">
					<el-col :span="6">
						<div id="" class="foodone">
							<img src="../../assets/img/img13.png">
							<div id="" class="foodMsg">
								<h2>鄂城鲫鱼</h2>
								<p>　“鄂城鲫鱼”的产地位于东经114°30′-115°05′，北纬30°01′-30°36′，产地水域主要是鄂州90里长港和梁子湖等水域范围。梁子湖和长港湖港相连，与鄂州长江相通。鄂城鲫鱼肉质细嫩，肉味鲜美，高蛋白，低脂肪，营养价值高。鄂城鲫鱼每百克鱼肉中含蛋白质≥16.0g，脂肪≥1.0g。并含有人体所需的全部8种必需氨基酸，还有多种维生素。</p>
								
								
							</div>
						
						</div>
					</el-col>
					<el-col :span="6">
						<div id="" class="foodone">
							<img src="../../assets/img/img12.png">
							<div id="" class="foodMsg">
								<h2>鄂城银鱼</h2>
								<p>鄂城银鱼，产地水域主要是鄂州90里长港和梁子湖，梁子湖和长港湖港相连，与鄂州长江相通。鄂城银鱼因体长略圆，细嫩透明，色泽如银而得名。鄂城银鱼的干银鱼的蛋白质含量为72.1%，脂肪含量为13%，高于其它食用鱼。每百克银鱼还含赖氨酸4820毫克，蛋氨酸2308毫克，异亮氨酸4176毫克，缬氨酸4396毫克，苏氨酸6652毫克，如此丰富的氨基酸，是其它鱼种所少见的。</p>
								
								
							</div>
							
						</div>
					</el-col>
					<el-col :span="6">
						<div id="" class="foodone">
							<img src="../../assets/img/img13.png">
							<div id="" class="foodMsg">
								<h2>　梁心番茄</h2>
								<p>红润鲜亮、果肉厚实，酸甜适中，茄红素含量高，是当今番茄品种中的极品。栽培中，禁用化肥、农药、生物调节剂，园区与华南农业大学、省农科院开展合作，采用以色列滴灌、覆膜除草、灯光诱蛾、物理防虫治病等技术，确保生产过程全有机、纯天然。经中国质量认证中心抽检，产品达到有机标准，园区获得有机产品转换证。</p>
							</div>
							
						</div>
					</el-col>
					<el-col :span="6">
						<div id="" class="foodone">
							<img src="../../assets/img/img14.png">
							<div id="" class="foodMsg">
								<h2>　吴都莹籼</h2>
								<p>吴都莹籼是鄂州的重要大米知名产品，可与武昌鱼齐名，它的主要特征是米细而粒长、长圆而饱满、米质好、腹白少、胶质率高，晶莹透亮而营养丰富。吴都莹籼的优良品质得益于鄂州市鄂城区长港两岸一马平川的种植环境，其产地位于90里长港两岸。因鄂城为三国时期吴王孙权之故都，故吴都莹籼之名一直沿用至今。</p>

							</div>
							
						</div>
					</el-col>
			
				</el-row>
			</div>

			</el-col>
		</el-row>

		<footerBox />
	</div>
</template>

<script>
	export default {

		components: {

		},
		data() {
			return {
				list:[{img:require('../../assets/img/img1.webp'),title:'春天来了，快来观花吧！',content:'近日气温上升，天气渐暖，景区的花儿们也争先空的舒展着身子，迎接阳光的关怀，大家快来与花儿们打打招呼吧'},
				{img:require('../../assets/img/img2.webp'),title:'疫情防控，不容忽视！',content:'在各地疫情复发的当下，请前来游玩的游客注意做好防护措施，准备口罩等防护用品，另：目前只接待绿色行程码的游客'},
				{img:require('../../assets/img/img3.webp'),title:'吴王避暑空今日游人量达50000次！',content:'春日来临，吴王避暑宫是游客游玩的最佳选择地'},
				{img:require('../../assets/img/img4.webp'),title:'春天来了，快来观花吧！',content:'近日气温上升，天气渐暖，景区的花儿们也争先空的舒展着身子，迎接阳光的关怀，大家快来与花儿们打打招呼吧'}]
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.hotbg {
		background-color: rgba(248, 248, 248, 1);
	}

	.topimg {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;

		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;

			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}

		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;

		}

		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;

		}

		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../../assets/img/fontbg.png) no-repeat bottom;

		}
	}

	.flex {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.hotContent {
		width: 100%;
		justify-content: flex-start;
		padding-bottom: 100px;

		li {
			margin: 0 0.5%;
			background-color: #FFFFFF;
			width: 24%;
			margin-bottom: 26px;
			cursor: pointer;

			&:hover {
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
			}

			img {
				width: 100%;
				height: 260px;
			}

			h6 {

				padding: 10px 20px;
				height: 72px;
				line-height: 36px;
				font-size: 24px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #000000;

			}

			p {
				padding: 0 20px 0px;
				height: 75px;
				line-height: 25px;
				font-size: 15px;
				font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
				font-weight: 400;
				color: #747474;

			}

			div {
				padding: 20px 20px 10px;

				span {
					&:nth-child(1) {
						color: #B2B2B2;
						font-size: 16px;
					}

					&:nth-child(2) {
						font-size: 18px;
						color: #000000;
					}
				}

			}
		}
	}
	
	
	.food {
		.foodone {
			width: 100%;
			height: 798px;
			position: relative;
			overflow: hidden;
	
			&:hover {
				
	
				.foodMsg {
					opacity: 1 !important;
				}
	
			}
	
			.foodMsg {
				opacity: 0;
				position: absolute;
				transition: 1s;
				background-color: rgba(0, 0, 0, 0.5);
				top: 0;
				left: 0;
				width: 100%;
				height: 528px;
				text-align: center;
				color: #FFFFFF;
	
				h2 {
					font-size: 28px;
					font-weight: bold;
					padding-top: 60px;
					padding-bottom: 30px;
				}
	
				p {
					padding: 0 30px;
					font-size: 18px;
					line-height: 32px;
					font-family: STKaiti;
					position: relative;
					cursor: pointer;
	
					
	
					&::after {
						content: '';
						width: 124px;
						height: 4px;
						background-color: #ffb114;
						position: absolute;
						bottom: 0;
						left: 50%;
						margin-left: -62px;
						opacity: 0;
					}
	
				}
	
				.foodbtn {
					position: absolute;
					bottom: 25px;
					left: 50%;
					margin-left: -42px;
					width: 94px;
					height: 22px;
					font-size: 14px;
					line-height: 22px;
	
					text-align: center;
					color: #d5b573;
					background: url(../../assets/img/fonttitle2.png) no-repeat;
					background-size: 100%;
				}
			}
	
			img {
				width: 100%;
				height: 528px;
			}
	
			.foodObtm {
				width: 100%;
				background-color: #a27b2c;
				height: 270px;
				text-align: center;
				color: #FFFFFF;
				position: relative;
	
				h2 {
					// text-align: center;
					font-size: 27px;
					padding: 25px 0;
					font-weight: 500;
				}
	
				p {
					font-size: 17px;
					line-height: 28px;
				}
	
				.foodObdiv {
					position: absolute;
					top: 10px;
					left: 2%;
					background-color: #FFFFFF;
					width: 96%;
					height: 240px;
					padding-top: 10px;
					opacity: 0;
					transition: 1s;
	
					h2 {
						font-size: 40px;
						color: #000000;
						font-family: NSimSun;
						padding: 0;
						line-height: 64px;
						position: relative;
						z-index: 0;
	
						&::after {
							content: '';
							position: absolute;
							margin-left: -62px;
							bottom: 10px;
							left: 50%;
							width: 125px;
							height: 12px;
							background-color: #ffb114;
							z-index: -1;
						}
					}
	
					h3 {
						font-family: xygpbt;
						font-size: 22px;
						color: #b7b7b7;
					}
	
					p {
						padding-top: 30px;
						margin: 0 10%;
						font-family: STFangsong;
						font-size: 20px;
						color: #676767;
						line-height: 30px;
	
					}
				}
			}
		}
	}
</style>
