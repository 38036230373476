<template>
	<div id="">
		<div class="orderTopTitle flex">
			<p style="width: 25%;">门票名称</p>
			<p style="width: 25%;">数量</p>
			<p style="width: 25%;">总价</p>
			<p style="width: 25%;">订单状态</p>
		</div>
		<ul id="" class="orderul">
			<li class="orderLi" v-for="(item,index) in items" :key="index" v-if="item.appletsOrderType == 1">
				<div id="" class="ordertopinfo flex justify_between align_start">
					<div id="" class="lefttopinfo">
						<h4>订单编号：{{item.ordOrderSn}}</h4>
					</div>
					<div id="" class="righttopinfo">
						下单时间: {{item.orderTime}}
					</div>
				</div>
				<div id="" class="flex justify_between goodinfoBox">
					<div id="" style="width: 20%;" class="">
						<h4 style="font-size: 22px;">{{item.ticketName}}</h4>
					</div>
					<div id="" class="goodNum">
						{{item.productNum}}
					</div>
					<div id="" class="goodNum">
						￥{{item.appletsOrderPayMoney/100}}
					</div>
					<!-- <div id="" style="width: 15%;" class="goodNum">
						{{item.ordReserveDate}}
					</div> -->
					<div v-if="item.appletsOrderStatus==101" style="width: 15%;" class="goodHndle flex_column flex" >
						<a href="javascript:;" style="font-size: 20px;color: #BE9775;" @click="goPayment(index)">立即支付</a>
					</div>
					<div v-else id="" style="width: 15%;" class="goodHndle">
						<div class="flex"  v-if="item.appletsOrderStatus==301 ||item.appletsOrderStatus ==201">
							<span>{{item.statusExp}}</span>
							<a  href="javascript:;" style="color: #BE9775;text-decoration: underline;" @click="showQrcode(index)">去使用</a>
						</div>
						<div class="flex"  v-else>
							<span></span>
							<span>{{item.statusExp}}</span>
						</div>
					</div>
				</div>
			</li>
		</ul>
		
		<el-dialog :visible.sync="visiableQrcode" width="50%">
			<div v-if="ticketData">
				<h2 style="font-size: 20px; text-align: center;margin-bottom: 10px;">门票二维码</h2>
				<div style="font-size: 13px; color: #828282;text-align: center;margin-bottom: 20px;">凭以下二维码直接扫码入园，无需换票</div>
				<div style="display: flex;flex-direction: row; justify-content: center;">
					<div v-for="(item,index) in ticetQrList" :key="index" style="margin: 15px;">
						<vue-qr  
						         ref="qrCode"   
						         :text="item.subParkCode"
						         :logoScale="40" 
						         :size="180" 
						         :margin="10"
						     /> 
						<div style="text-align: center;font-size: 12px; color: #828282;">可通行{{item.ticWillPassNum}}人，已通行{{item.ticPassNum||0}}人</div>
					</div>
				</div>
				
				
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {getTickerOrderDetail} from '@/api/public.js'
	import{formatTraveDate ,ymd_hhmmss} from '@/utils/format.js'
	import VueQr from 'vue-qr'
	export default{
		components: {
			VueQr
		},
		props: {
			items: {
				type: Array,
				default: function(){
					return []
				}
			},
		},
		data() {
			return {
				visiableQrcode: false,
				ticketData:null,
				ticetQrList:[]
			}
		},
		methods: {
			goPayment(index) {
				let item =this.items[index]
				this.$router.push({
					path:'/ordersPayment?orderId='+item.appletsOrderId+'&type=1'
				})
			},
			showQrcode(index){
				let item =this.items[index]
				getTickerOrderDetail({ordId:item.appletsOrderId}).then(data=>{
					let res= data.data;
					if(res.code==200){
						
						let ticketInfo =res.data;
						let ticetQrList = res.data.subOrderList;
						ticketInfo.subStartTime = formatTraveDate(ticetQrList[0].subTicketType, ticetQrList[0].subStartTime);
						ticketInfo.subEndTime = formatTraveDate(ticetQrList[0].subTicketType, ticetQrList[0].subEndTime);
						ticketInfo.ordAddTime = ymd_hhmmss(ticketInfo.ordAddTime);
						this.ticetQrList =ticetQrList;
						this.ticketData =ticketInfo
						this.visiableQrcode=true;
					}else{
						
					}
				})
				
			}
		},
	}
</script>

<style>
</style>
