<template>
	<div>
		<nav-box></nav-box>
		<div class="site_banner">
			<img src="../../assets/img/xishan-bg1.png">
			<!-- <div class="blank_20"></div> -->
		</div>
		<div class="main padd_top50" style="margin-top:70px">
			<div class="wrap">
				<div class="personal_news_d">
					<h1 class="fnt_24">{{article.title}}</h1>
					<div class="d_span">
						<span>发布时间：{{article.updateTime}}</span>
					</div>
					<div class="edit_con_original edit-con-original">
						<div v-html="article.content"></div>
					</div>
				</div>
		 </div>


		</div>
		<footerBox />
	</div>
</template>

<script>
	import{getArticleList , getArticleDetail} from '@/api/content.js'
	export default{
		data() {
			return {
				id:null,
				article:{}
			}
		},
		created() {
			this.id =this.$route.query.id;
			if(this.id){
				this.getData();
			}
		},
		methods: {
			getData() {
				getArticleDetail(this.id).then(res=>{
					if(res.data && res.data.data ){
						let article =res.data.data;
						let content =article.content;
						content =content.replace(/\.\.\/hdstorApi/g,'https://www.ezhouxishan.com/hdstorApi')
						article.content =content;
						this.article =article;
					}
				})
			}
		},
	}
</script>

<style>
	.edit_con_original{
		margin-bottom: 80px;
	}
</style>
