<template>
	<div id="" class="addressbox">
		<div id="" class="addAddress">
			<span id="" @click="addAddress">
				<i class="el-icon-plus"></i> 添加地址
			</span>
		</div>
		<div id="" class="addressUL">
			<div id="" class="addressli addressTitle flex">
				<p>收件人</p>
				<p>所在地区</p>
				<p>详细地址</p>
				<p>手机号码</p>
				<p>操作</p>
			</div>
			<div id="" class="addressli  flex">
				<p class="pfont">王华华</p>
				<p class="pfont">湖北省武汉市洪山区</p>
				<p class="pfont">光谷总部国际10089号</p>
				<p class="pfont">17300000001</p>
				<p > <button class="pfont">修改</button> <button class="pfont">删除</button><button class="pfont">默认地址</button> </p>
			</div>

		</div>
		<div id="" class="dialogBox">
			<el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
				<h2>添加地址</h2>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="收货人" prop="name">
						<el-input v-model="ruleForm.name" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="region">
						<el-input type="number" v-model="ruleForm.name" placeholder="请输入手机号"></el-input>
					</el-form-item>
					<el-form-item label="所在地区" prop="region">
						<el-cascader placeholder="请选择所在地区" ref="addressCascader" v-model="ruleForm.region"
							:options="provinceData" :props="addressProps" style="width: 100%" filterable clearable
							@change="handleChange"></el-cascader>
					</el-form-item>
					<el-form-item label="详细地址" prop="desc">
						<el-input type="textarea" v-model="ruleForm.desc" placeholder="请输入详细地址"></el-input>
					</el-form-item>

				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button  class="commitBtn" @click="dialogVisible = false">提交</el-button>
					<el-button  class="cancleBtn"  @click="dialogVisible = false">取 消</el-button>
					
				</span>
			</el-dialog>
		</div>

	</div>
</template>

<script>
	import chinaCityData from "@/utils/json/chinaCity.json";
	export default {
		data() {
			return {
				dialogVisible: false,
				ruleForm: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}, ],
					region: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					date1: [{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '请至少选择一个活动性质',
						trigger: 'change'
					}],
					resource: [{
						required: true,
						message: '请选择活动资源',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请填写活动形式',
						trigger: 'blur'
					}]
				},
				provinceData: [],
				addressProps: {
					label: "name",
					value: "name",
					children: "cityList",
				},

			}
		},
		created() {
			this.provinceData = chinaCityData;
		},
		methods: {
			handleChange(value) {
				console.log(value);
				if (value && value.length > 0) {
					this.ruleForm.province = value[0]
					this.ruleForm.city = value[1]
					this.ruleForm.region = value[2]
					this.queryTeacher()
				}
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			addAddress() {
				this.dialogVisible = true
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="less">
	.pfont{
		font-size: 20px!important;
		line-height: 30px!important;
	}
	.flex {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.flex_column {
		flex-direction: column;
	}

	.justify_start {
		justify-content: flex-start;
	}

	.justify_between {
		justify-content: space-between;
	}

	.align_end {
		align-items: flex-end;
	}

	.align_start {
		align-items: flex-start;
	}

	.addressbox {
		padding: 30px 0;
	}

	.addAddress {
		text-align: right;
		span {
			text-align: center;
			display: inline-block;
			background: #BE9775;
			width: 164px;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			// font-weight: bold;
			color: #FFFFFF;
			cursor: pointer;
		}
	}

	.addressUL {
		margin-top: 35px;
	}

	.addressTitle {
		background: #BE9775;

		p {
			color: #FFFFFF !important;
		}

	}

	.addressli {
		height: 80px;
		border-bottom: 1px solid #F0F0F0;
		p {
			text-align: center;
			font-size: 22px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 32px;
			padding: 0 10px;

			&:nth-child(1) {
				width: 10%;
			}

			&:nth-child(2) {
				width: 25%;
			}

			&:nth-child(3) {
				width: 30%;
			}

			&:nth-child(4) {
				width: 15%;
			}

			&:nth-child(5) {
				width: 20%;

				button {
					padding: 0 10px;
				}
			}
		}

	}

	.dialogBox {
		h2 {
			text-align: center;
			padding-bottom: 20px;
			font-size: 28px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		.el-dialog__body {
			padding: 10px 20px;
		}
		.el-form-item__label {
			
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #575757;
		}
		.el-input__inner{
			border-radius: 0px;
			line-height: 32px;
			height: 32px;
		}
		.el-textarea__inner{
			border-radius: 0px;
		}
		.el-dialog__footer{
			text-align: center!important;
			padding-bottom: 40px;
		}
		.el-button{
			border-radius: 0;
			padding: 12px 50px;
			background: #BE9775;
			font-size: 22px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			border: 0;
		}
		.cancleBtn{
			background: #E6E6E6;
			color: #9C9C9C;
			margin-left: 40px;
		}
	}
	
</style>
