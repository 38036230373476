<template>
	<div>
		<nav-box></nav-box>
		<div style="height: auto;background-color: rgba(0,0,0,0.4);padding-top: 100px;width: 100%;">
			<img style="width: 100%;height: 100%;" src="../assets/image/loginimg.png">
		</div>
		<div id="app" class="main">
			<div id="content"></div>
			<div class="login_cont">
				<div class="wrap">
					<div class="login_box">
						<div class="left">
							<div class="left_box">
								<h1 class="fnt_36">欢迎来到鄂州西山风景区</h1>
								<div class="login_img"><img src="https://gboss.wtown.com/pc/images/login_img.jpg"></div>
							</div>
						</div>
						<div class="right">
							<div class="login_park">
								<div style="display: flex;">
									<h2 class="fnt_30 " :class="loginActive==1?'active':''" @click="loginOne(1)"><a  >短信登录</a></h2>
									<h2 v-if="false" class="fnt_30" :class="loginActive==2?'active':''" style="margin-left: 20px;" @click="loginOne(2)"><a >密码登录</a></h2>
								</div>
								<div class="login_form">
									<ul>
										<li><input type="text" v-model="formData.mobile" id="mobile"
												placeholder="手机号"></li>
										<li v-if="loginActive==2"><input type="password" id="password" placeholder="请输入密码" ></li>
										<li class="code_w" v-if="loginActive==1">
											<div class="form_w" style="display: flex;">
												<input v-model="formData.code" id="code" placeholder="请输入短信验证码"
													type="text">
												<span class="code_img">
													<div class="code code_text"></div>
													<input id="btnSendCode01" type="button" v-model="vcodeBtnText"
														class="code" @click="sendVcode">
												</span>
											</div>
										</li>
									</ul>
								</div>
								<div class="login_btn"><button @click="doLogin()" class="fnt_16">登录</button>
								 <!-- <a href="forget_phone.html" class="forget_a"><i class="iconfont icon-wen"></i>忘记密码？</a> -->
								 </div>
								<!-- <div style="margin-top: 8px;">还没有账号？<a href="/register">
									<span style="color: rgb(39, 76, 168); text-decoration: underline;">点击注册</span></a>
								</div> -->
								<div style="margin-top: 8px;">
									未注册手机号，将自动创建账号
								</div>
								<!---->
								<div class="other_block">
									<div class="other_login">
										<ul></ul>
									</div>
								</div>
							</div>
						</div>
						<div class="login_line"></div>
					</div>
				</div>
			</div>
		</div>
		<footerBox />
	</div>
</template>

<script>
	import {mapState ,mapMutations} from 'vuex'
	import {
		sendSmsCode,
		mobileLogin
	} from '@/api/user.js'
	const countNum = 60,
		btnText = '获取验证码';
	export default {
		data() {
			return {
				vcodeBtnText: btnText,
				countNum: 60,
				countDownTimer: null,
				formData: {
					mobile: '',
					code: ''
				},
				loginActive:1
			}
		},
		computed: {
			...mapState({
				hasLogin: state => state.user.hasLogin,
			}),
		},
		created() {
			console.log(this.hasLogin)
		},
		methods: {
			...mapMutations({
				login:'user/login'
			}),
			loginOne(val){
				this.loginActive=val
			},
			showError(msg) {
				this.$message({
					showClose: true,
					message: msg,
					type: 'error',
					offset: 100
				});
			},
			sendVcode() {
				if (!this.formData.mobile) {
					return this.showError('请输入正确手机号码');
				}
				let type = '';
				// 阻止按钮被多次点击 
				if (this.vcodeBtnText != btnText) {
					return;
				}
				this.vcodeBtnText = '发送中';
				sendSmsCode(this.formData.mobile).then(res => {
					if (res.data.code != 200) {
						return this.showError(res.data.msg ? res.data.msg : '发送失败');
						this.vcodeBtnText = btnText;
					} else {
						this.countDownTimer = setInterval(() => {
							this.countDown(type);
						}, 1000);
					}
				})
			},
			countDown(type) {
				if (this.countNum < 1) {
					clearInterval(this.countDownTimer);
					this.countDownTimer = null;
					this.vcodeBtnText = btnText;
					this.countNum = countNum;
					return;
				}
				this.countNum--;
				this.vcodeBtnText = '重新发送' + '(' + this.countNum + ')';
			},
			doLogin() {
				
				if (!this.formData.mobile) {
					return this.showError('请输入正确手机号码');
				}
				if (!this.formData.code) {
					return this.showError('请输入正确验证码')
				}
				mobileLogin(this.formData).then(res => {
					if (res.data.code == 200) {
						// login data
						let loginData={
							userInfo:{
								username:this.formData.mobile,
								mobile:this.formData.mobile,
								userId:res.data.data.userInfo.userId
							},
							tokenInfo:res.data.data, //tokenExpire，token
						};
						this.login(loginData);
						this.$router.replace({
							path:'/peopleInfo'
						})
					} else {
						return this.showError(res.data.msg ? res.data.msg : '登录失败');
					}
				})
			}
		},
	}
</script>

<style scoped>
	.active {
		border-bottom: 2px solid #000;
	}
	.fnt_30{
		cursor: pointer;
	}
	.active{
		
	}
</style>
