<template>
	<div>
		<nav-box></nav-box>

		<div id="app" class="main">
			<div class="infotopimg">
				<img src="../../assets/image/loginimg.png">
				<div id="" class="userInfoBox">
					<img src="../../assets/image/logo.png">
					<p class="infoname"><span id="">{{user.username}}</span></p>
					<!-- <p class="infoname" @click="openEditInfo"><span id="">用户名</span> <i class="el-icon-edit"></i></p> -->
				</div>
			</div>
			<div id="content">
				<div id="" class="orderNav">
					<div id="">
						<p :class="{'activep':NowActive==2}" @click="choseNav(2)">门票订单</p>
						<!-- <p :class="{'activep':NowActive==3}" @click="choseNav(3)">酒店订单</p> -->
						<p :class="{'activep':NowActive==4}" @click="choseNav(4)">我的预约</p>
						<p :class="{'activep':NowActive==5}" @click="choseNav(5)">游客信息</p>
						<!-- <p :class="{'activep':NowActive==6}" @click="choseNav(6)">地址管理</p> -->
					</div>
				</div>
				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="20" :xs="24" v-if="NowActive==1||NowActive==2||NowActive==3||NowActive==4">
						<!-- <div id="" class="smallnav">
							<p :class="{'smallActiveNav':NowStatus==1}" @click="choseStatus(1)">全部</p>
							<p :class="{'smallActiveNav':NowStatus==2}" @click="choseStatus(2)">待支付</p>
							<p :class="{'smallActiveNav':NowStatus==3}" @click="choseStatus(3)">已支付</p>
							<p :class="{'smallActiveNav':NowStatus==4}" @click="choseStatus(4)">已取消</p>
							<p :class="{'smallActiveNav':NowStatus==5}" @click="choseStatus(5)">已消费</p>
						</div>
						 -->
						 <div style="height:30px"></div>
						<tickerOrders v-if="NowActive==2" :items ="items"></tickerOrders>
						<subscribeOrders v-if="NowActive==4" :items="subscribeData.items"></subscribeOrders>
					</el-col>
					<el-col :span="20" :xs="24" v-if="NowActive==5">
						<touristCom  :items="travelsData.items" @save="saveTravel" @remove="removePeople"/>
					</el-col>
					<el-col :span="20" :xs="24" v-if="NowActive==6">
						<addressComponent />
					</el-col>
				</el-row>
			</div>

		</div>
		<div id="" class="editInfoBox">
			<el-dialog :visible.sync="editInfoVisible" width="30%">
				<h2>修改个人信息</h2>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="用户名" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="身份证号" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="手机号" prop="phone">
						<el-input v-model="ruleForm.phone"></el-input>
					</el-form-item>
					<el-form-item label="性别" prop="resource">
						<!-- <el-radio-group v-model="ruleForm.resource"> -->
						<el-radio v-model="ruleForm.resource" label="1">男</el-radio>
						<el-radio v-model="ruleForm.resource" label="2">女</el-radio>
						<!-- </el-radio-group> -->
					</el-form-item>
					<el-form-item label="所在地区" prop="region">
						<el-cascader placeholder="请选择所在地区" ref="addressCascader" v-model="ruleForm.region"
							:options="provinceData" :props="addressProps" style="width: 100%" filterable clearable
						></el-cascader>
					</el-form-item>

					<el-form-item label="详细地址" prop="desc">
						<el-input type="textarea" v-model="ruleForm.desc"></el-input>
					</el-form-item>

				</el-form>

				<span slot="footer" class="dialog-footer">
					<el-button @click="submitForm('ruleForm')">提交</el-button>
					<el-button class="cancleBtn" @click="resetForm('ruleForm')">取 消</el-button>
				</span>
			</el-dialog>
		</div>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import {
		sendSmsCode,
		mobileLogin
	} from '@/api/user.js'
	import chinaCityData from "@/utils/json/chinaCity.json";
	import addressComponent from "./components/address.vue"
	import touristCom from './components/tourist.vue'
	import tickerOrders from './components/ticketOrders.vue'
	import subscribeOrders from './components/subscribeOrders.vue'
	const countNum = 60,
		btnText = '获取验证码';
	
	import {mapState ,mapMutations} from 'vuex'
	import {getOrders ,getSubscribeOrders ,getTravels,createTravel ,removeTravel} from '@/api/public.js'
	import {formatTraveDate,ymd_hhmmss,ymd} from '@/utils/format.js'
	export default {
		components: {
			addressComponent,
			touristCom,
			tickerOrders,
			subscribeOrders
		},
		computed: {
			...mapState({
				hasLogin: state => state.user.hasLogin,
				user: state => state.user.user,
			}),
		},
		data() {
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入电话号码'));
				} else {
					if (!(/^1[3-9][0-9]\d{4,8}$/.test(value))) {
						callback(new Error('电话号码格式不正确'));
					}

				}
			}
			return {
				vcodeBtnText: btnText,
				countNum: 60,
				countDownTimer: null,
				formData: {
					mobile: '',
					code: ''
				},
				NowActive: 2,
				editInfoVisible: false,
				ruleForm: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					region: [{
						required: true,
						message: '请选择活动区域',
						trigger: 'change'
					}],
					phone: [{
						validator: validatePhone,
						trigger: 'blur'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '请至少选择一个活动性质',
						trigger: 'change'
					}],
					resource: [{
						required: true,
						message: '请选择活动资源',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请填写活动形式',
						trigger: 'blur'
					}]
				},
				provinceData: [],
				addressProps: {
					label: "name",
					value: "name",
					children: "cityList",
				},
				NowStatus: 1,
				
				items:[],
				page:1,
				total:0,
			
				subscribeData:{
					items:[],
					page:1,
					total:0,
					hasNext:true
				},
				travelsData:{
					items:[],
					page:1,
					total:0,
					hasNext:true
				}
			}
		},
		created() {
			this.provinceData = chinaCityData;
			this.getOrderData()
			this.getSubscribeData()
			this.geTravelsData();
		},
		methods: {
			getOrderData(){
				if(this.refresh){
					this.page=1;
				}
				let data = {
				    userId:this.user.userId,
				    orderStatus: '',
				    page: this.page,
				    limit: 10
				};
				console.log(data)
				getOrders(data).then( (_res)=>{
					let res =_res.data;
					if(res.code==200){
						this.hasLoad=true;
						this.hasNext =res.data.hasNext;
						this.page++;
						let items = res.data.items;
						items.forEach((item) => {
						    item.startTime = formatTraveDate(item.ordTicValidType, item.ordStartTime);
						    item.endTime = formatTraveDate(item.ordTicValidType, item.ordEndTime);
						    item.orderTime = ymd_hhmmss(item.orderTime);
						
						    switch (item.appletsOrderStatus) {
						        case 101:
						            item.statusExp = `待付款`;
						            break;
						
						        case 102:
						        case 103:
						            item.statusExp = `已取消`;
						            break;
						
						        case 201:
						            if (item.appletsOrderType == 1) {
						                item.statusExp = `已付款`;
						            } else {
						                item.statusExp = `待发货`;
						            }
						
						            break;
						
						        case 202:
						            item.statusExp = `退款中`;
						            break;
						
						        case 203:
						            item.statusExp = `已退款`;
						            break;
						
						        case 204:
						            item.statusExp = `已撤单`;
						            break;
						
						        case 301:
						            if (item.appletsOrderType == 1) {
						                item.statusExp = `已出票`;
						            } else {
						                item.statusExp = `已发货`;
						            }
						
						            break;
						
						        case 302:
						            item.statusExp = `已收货`;
						            break;
						
						        case 401:
						            item.statusExp = `使用中`;
						            break;
						
						        case 402:
						            item.statusExp = `已使用`;
						            break;
						
						        case 501:
						            item.statusExp = `已过期`;
						            break;
						
						        case 502:
						            item.statusExp = `已失效`;
						            break;
						
						        case 601:
						            item.statusExp = `交易完成`;
						            break;
						    }
						});
						if(this.refresh){
							this.items =items;
						}else{
							this.items =this.items.concat(items)
						}
						this.refresh=false;
					}
				})
			},
			goOrderDetail(item){
				
				
			},
			getSubscribeData(){
				let data = {
				 mesUserId:this.user.userId,
				 page: this.subscribeData.page,
				 mesStatus:-1,
				 limit: 10
				}
				getSubscribeOrders(data).then( (_res)=>{
					let res =_res.data;
					if(res.code==200){
						this.subscribeData.hasNext=res.data.hasNext;
						this.subscribeData.page++;
						let items =res.data.items
						items.forEach(item=>{
						  item.mesYMDTime=ymd(item.mesTime)
						  item.mesHMMTime=ymd_hhmmss(item.mesAddTime).substring(11)
						})
						if(this.subscribeData.refresh){
							this.subscribeData.items =items;
						}else{
							this.subscribeData.items =this.items.concat(items);
						}
						this.subscribeData.refresh=false;
						
					}
				})
			},
			geTravelsData() {
				if (this.travelsData.refresh) {
					this.travelsData.page = 1;
					this.travelsData.hasNext = true;
				}
				let data = {
					traUserId: this.user.userId,
					page: this.travelsData.page,
					limit: 30
				};
				getTravels(data).then((_res) => {
					let res =_res.data;
					if (res.code == 200) {
						this.travelsData.hasLoad = true;
						this.travelsData.hasNext = res.data.hasNext;
						this.travelsData.page++;
						let items = res.data.data;
						items.map((item) => {
							item.traIcCard = item.traUcardId.replace(/(\d{4})\d{10}(\d{4})/,
								'$1**********$2');
							item.traPhone = item.traMobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
						});
						if (this.travelsData.refresh) {
							this.travelsData.items = items;
						} else {
							this.travelsData.items = this.items.concat(items)
						}
						this.travelsData.refresh = false;
					}
				});
			},
			savePeople(data) {
				createTravel(data).then( (_res) => {
					let res = _res.data;
					if (res.code == 200) {
						this.geTravelsData();
					} else {
						this.showError(res.msg)
					}
				});
			
			},
		
			removePeople(index) {
				let item = this.travelsData.items[index];
				removeTravel(item.traId).then( (res) => {
					if (res.data.code == 200) {
						this.travelsData.items.splice(index, 1);
					} else {
						this.showError('删除失败');
					}
				})
			},
			choseStatus(i) {
				this.NowStatus = i
			},
			submitForm(formName) {

				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.editInfoVisible = false
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.editInfoVisible = false
				this.$refs[formName].resetFields();
			},
			openEditInfo() {
				this.editInfoVisible = true
			},
			choseNav(i) {
				this.NowActive = i
			},
			showError(msg) {
				this.$message({
					showClose: true,
					message: msg,
					type: 'error',
					offset: 100
				});
			},
			sendVcode() {
				if (!this.formData.mobile) {
					return this.showError('请输入正确手机号码');
				}
				let type = '';
				// 阻止按钮被多次点击 
				if (this.vcodeBtnText != btnText) {
					return;
				}
				this.vcodeBtnText = '发送中';
				sendSmsCode(this.formData.mobile).then(res => {
					if (res.data.code != 200) {
						return this.showError(res.data.msg ? res.data.msg : '发送失败');
						this.vcodeBtnText = btnText;
					} else {
						this.countDownTimer = setInterval(() => {
							this.countDown(type);
						}, 1000);
					}
				})
			},
			countDown(type) {
				if (this.countNum < 1) {
					clearInterval(this.countDownTimer);
					this.countDownTimer = null;
					this.vcodeBtnText = btnText;
					this.countNum = countNum;
					return;
				}
				this.countNum--;
				this.vcodeBtnText = '重新发送' + '(' + this.countNum + ')';
			},
			doLogin() {
				if (!this.formData.mobile) {
					return this.showError('请输入正确手机号码');
				}
				if (!this.formData.code) {
					return this.showError('请输入正确验证码')
				}
				mobileLogin(this.formData).then(res => {
					if (res.code == 200) {
						// login data
					} else {
						return this.showError(res.data.msg ? res.data.msg : '登录失败');
					}
				})
			}
		},
	}
</script>

<style lang="less">
	.infotopimg {
		margin-top: 100px;
		width: 100%;
		height: auto;
		position: relative;
	}

	.infotopimg img {
		width: 100%;
		height: auto;
	}

	.active {
		border-bottom: 2px solid #000;
	}

	.userInfoBox {
		width: 140px;
		height: 140px;
		border-radius: 50%;
		background-color: #FFFFFF;
		position: absolute;
		bottom: -42px;
		left: 10%;

		.infoname {
			position: absolute;
			top: 54px;
			min-width: 300px;
			left: 150px;
			font-size: 24px;
			color: #FFFFFF;
			cursor: pointer;
		}
	}

	.orderNav {
		width: 100%;
		height: 48px;
		background: #F8F8F8;
		padding-top: 72px;
		cursor: pointer;

		div {
			display: flex;
			justify-content: space-between;
			width: 68%;
			margin: 0 auto;

			p {

				line-height: 26px;
				font-size: 22px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
		}

		.activep {
			position: relative;

			&::after {
				content: '';
				width: 100%;
				height: 3px;
				background-color: #AE7520;
				position: absolute;
				bottom: -22px;
				left: 0;
			}
		}
	}

	.smallnav {
		height: 122px;
		display: flex;
		align-items: center;

		p {
			margin-right: 40px;
			line-height: 26px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			cursor: pointer;
		}

		.smallActiveNav {
			color: #AE7520;
		}
	}

	.flex {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.flex_column {
		flex-direction: column;
	}

	.justify_start {
		justify-content: flex-start;
	}

	.justify_between {
		justify-content: space-between;
	}

	.align_end {
		align-items: flex-end;
	}

	.align_start {
		align-items: flex-start;
	}

	.orderTopTitle {
		// width: 100%;
		height: 80px;
		line-height: 100px;
		background: #BE9775;
		padding: 0 45px;

		p {
			text-align: center;
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
		}

	}

	.orderul {
		.orderLi {
			border: 15px solid #f8f8f8;
			margin: 20px 0;
			background-color: #FFFFFF;
			padding: 20px 0;

			.ordertopinfo {
				padding: 0 40px;
				border-bottom: 1px solid #F0F0F0;

				.lefttopinfo {
					h4 {

						font-size: 26px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					p {
						margin: 10px 0 20px;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #AE7520;

						i {
							margin-right: 6px;
						}
					}
				}

				.righttopinfo {

					font-size: 22px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
			}

			.goodinfoBox {
				padding: 30px 40px 0;
			}

			.goodinfo {
				width: 40%;
				margin-right: 5%;

				img {
					width: 140px;
					height: 140px;
					margin-right: 24px;
				}

				.goodinfoDetail {
					flex: 1;

					h4 {

						font-size: 22px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 32px;
						height: 64px;
					}

					h5 {

						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #989898;
						line-height: 40px;
					}

					p {

						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 40px;
					}
				}
			}

			.goodNum {

				width: 10%;
				font-size: 22px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 32px;
			}

			.goodHndle {
				.buttonin {
					font-size: 22px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					line-height: 44px;

					&:hover {
						color: #AE7520;
					}
				}

			}
		}

	}

	.editInfoBox {
		h2 {
			text-align: center;
			padding-bottom: 20px;
			font-size: 28px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
		}

		.el-dialog__body {
			padding: 10px 20px;
		}

		.el-form-item__label {

			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #575757;
		}

		.el-input__inner {
			border-radius: 0px;
			line-height: 32px;
			height: 32px;
		}

		.el-textarea__inner {
			border-radius: 0px;
		}

		.el-dialog__footer {
			text-align: center !important;
			padding-bottom: 40px;
		}

		.el-button {
			border-radius: 0;
			padding: 12px 50px;
			background: #BE9775;
			font-size: 22px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			border: 0;
		}

		.cancleBtn {
			background: #E6E6E6;
			color: #9C9C9C;
			margin-left: 40px;
		}

		.el-radio__input.is-checked+.el-radio__label {
			color: #575757 !important;
		}

		.el-radio__input.is-checked .el-radio__inner {
			background-color: #BE9775 !important;
			border-color: #BE9775 !important;
		}
	}
</style>
