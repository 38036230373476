<template>
	<div class="three">
		<nav-box></nav-box>
		<div>
			<div id="app" class="main">
				<div class="site_banner">
					<img src="../../assets/img/xishan-bg4.jpg">
				</div>

				<el-row type="flex" class="row-bg" justify="center">
					<el-col :span="20" :xs="24">
						<Title title="门票预定"></Title>
						
						<div class="">
							<div class="ticket_list">
								<ul>
									<li v-for="(item,index) in List" :key='index'>
										<div class="ticket_box">
											<dl>
												<dt><a href="javascript:void(0);" class="tran_scale"><img data-ratio="0.56418"
															onerror="lod(this)" :src="item.ticPicture" class="ratio-img"
															style="height: 189px;"></a></dt>
												<dd>
													<h3 class="fnt_24"><a
															href="javascript:void(0);">{{item.ticName}}</a></h3>
													<div class="hptel_p01">
														<!-- <span style="width: auto; margin-top: 6px;">酒店配套：</span> -->
														<span v-for="(Tag,index) in item.ticTag" :key="index" style="margin-right: 12px;">{{Tag}}</span>
													</div>
													<div class="hptel_p01">
														<p>当天{{item.ticLastTime}}停止发售</p>
														<p>剩余：{{item.ticketStock}}张</p>
													</div>
												</dd>
											</dl>
											<div class="ticket_re"><span style="display: flex; align-items: center;">
												<span class="fnt_18">￥<b class="fnt_24">{{item.ticOriginalPrice/100}}</b></span>
												</span> <a :href="'/ticketDetail?id='+item.ticId" class="onecard_abtn">立即预订</a></div>
										</div>
									</li>
						
								</ul>
							</div>
						</div>
					</el-col>
				</el-row>

				
			</div>
		</div>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	import NavBox from '@/components/navBox.vue'
	export default {
		name: 'three',
		components: {
			NavBox
		},
		data() {
			return {
				List: []
			}
		},
		computed: {

		},
		created() {
			this.getList()
		},
		methods: {
			getList() {
				let data = {
					siteIsOpen: 1,
					page: 1,
					limit: 1000
				}
				this.$http.ticket(data).then(res => {
					let data = res.data.data;
					if (data && data.items) {
						this.List = data.items;

					}

					console.log(this.List)
				})
			}


		}
	}
</script>
<style lang="less" scoped>

</style>
