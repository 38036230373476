import { render, staticRenderFns } from "./mediaFocus.vue?vue&type=template&id=8bac8b00&scoped=true&"
import script from "./mediaFocus.vue?vue&type=script&lang=js&"
export * from "./mediaFocus.vue?vue&type=script&lang=js&"
import style0 from "./mediaFocus.vue?vue&type=style&index=0&id=8bac8b00&lang=less&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bac8b00",
  null
  
)

export default component.exports