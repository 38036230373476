<template>
	<div id="" class="humbox" style="background-color: #fbf9f7;">
		<img class="bgimg" src="../../assets/img/subg.png">
		<nav-box></nav-box>
		<div id="" class="topimg">
			<img src="../../assets/img/topImg.png">
		</div>

		<el-row type="flex" class="row-bg" justify="center">
			<el-col :span="20" :xs="24">
				<h2 class="H2title">
					<p>XISHAN SURVEY</p>
					<span id="">
						人文西山
					</span>
					<div id="" class="guidtitle">
						<a href="/">首页></a><a href="/">人文西山</a>
					</div>
				</h2>
				<div class="verseandp" style="">
					<el-row type="flex" class="row-bg" justify="space-around" align="middle">
						<el-col :span="9" :xs="24">
							<div id=""  class="animate__animated puername"
											:class="{'animate__fadeInLeft':imgshow}">
								<img src="../../assets/img/suimg2.png">
								<h3>黄庭坚</h3>
								<h4>黄庭坚（1045年6月12日—1105年9月30日），字鲁直，乳名绳权，号清风阁、山谷道人、山谷老人、涪翁、涪皤、摩围老人、黔安居士、八桂老人，世称黄山谷、黄太史、黄文节、豫章先生。宋江南西路洪州府分宁人（今江西省九江市修水县）人。祖籍浙江省金华市。北宋诗人黄庶之子，南宋中奉大夫黄相之父。北宋大孝子，《二十四孝》中“涤亲溺器”故事的主角。北宋著名文学家、书法家、江西诗派开山之祖。
								</h4>
							</div>
						</el-col>
						<el-col :span="9" :xs="24">
							<div id=""  class="animate__animated versebox"
											:class="{'animate__fadeInRight':imgshow}">
								<h1>松风阁</h1>
								<h2>宋黄庭坚</h2>
								<p class="borderRight">老松魁梧数百年</p>
								<p>斧斤所赦今参天</p>
								<p>风鸣娲皇五十弦</p>
								<p>洗耳不须菩萨泉</p>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="verseandp" style="">
					<el-row type="flex" class="row-bg" justify="space-around" align="middle">
						<el-col :span="9" :xs="24">
							<div id=""  class="animate__animated versebox"
											:class="{'animate__fadeInLeft':moretoShow}">
								<h1>武昌西山诗并引</h1>
								<h2>苏东坡</h2>
								<p class="borderRight">春江渌涨葡萄醅</p>
								<p>武昌官柳知谁栽</p>
								<p>忆从樊口载春酒</p>
								<p>步上西山寻野梅</p>
							</div>
						</el-col>
						<el-col :span="9" :xs="24">
							<div id=""  class="animate__animated puername"
											:class="{'animate__fadeInRight':moretoShow}">
								<img src="../../assets/img/suimg1.png">
								<h3>苏东坡</h3>
								<h4>苏轼（1037年1月8日—1101年8月24日），字子瞻，一字和仲，号铁冠道人、东坡居士，世称苏东坡、苏仙 [1-3] 、坡仙 [91]
									，汉族，眉州眉山（今四川省眉山市）人，祖籍河北栾城，北宋文学家、书法家、美食家、画家，历史治水名人
								</h4>
							</div>
						</el-col>

					</el-row>
				</div>
				<div class="verseandp" style="border: 0;">
					<el-row type="flex" class="row-bg" justify="space-around" align="middle">
						<el-col :span="9" :xs="24">
							<div id=""  class="animate__animated puername"
											:class="{'animate__fadeInLeft':moreto2Show}">
								<img src="../../assets/img/suimg3.png">
								<h3>苏辙</h3>
								<h4>苏辙（公元1039年3月18日 [1] －1112年10月25日 [2] ），字子由，一字同叔 [3]
									，晚号颍滨遗老。眉州眉山（今属四川）人。北宋时期官员、文学家，“唐宋八大家”之一。
								</h4>
							</div>
						</el-col>
						<el-col :span="9" :xs="24">
							<div id=""  class="animate__animated versebox"
											:class="{'animate__fadeInRight':moreto2Show}">
								<h1>陪子瞻游武昌西山</h1>
								<h2>苏辙</h2>
								<p class="borderRight">策杖看万松</p>
								<p>流汗升九曲</p>
								<p>苍茫大江涌</p>
								<p>浩荡众山蹙</p>
							</div>
						</el-col>
					</el-row>
				</div>

			</el-col>
		</el-row>
		<div id="" class="padding_bottom"></div>
		<footerBox />
	</div>
</template>

<script>
	export default {
		name: "Home",
		components: {
		
		},
		data() {
			return {
				imgshow:false,
				moretoShow:false,
				moreto2Show:false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		
		},
		methods:{
			handleScroll() {
				let scrollTop = document.documentElement.scrollTop;
				if (scrollTop >= 100) {
					this.imgshow = true
				} else {
					this.imgshow = false
				}
				if (scrollTop >= 1000) {
					this.moretoShow = true
				} else {
					this.moretoShow = false
				}
				if (scrollTop >= 2000) {
					this.moreto2Show = true
				} else {
					this.moreto2Show = false
				}
			
				
			
				console.log(document.documentElement.scrollTop);
			},
		}
		}
</script>

<style lang="less">
	.topimg {
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.H2title {
		text-align: center;
		width: 100%;
		padding: 90px 0 90px;
		position: relative;
		z-index: 0;

		.guidtitle {
			position: absolute;
			top: 185px;
			right: 0px;

			a {
				font-size: 16px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #B2B2B2;
			}
		}

		p {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 128px;
			left: 0;
			font-size: 82px;
			line-height: 108px;
			font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
			font-weight: 300;
			color: rgba(203, 185, 124, 0.3);
			z-index: -1;
			font-family: Microsoft YaHei UI;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 120px;
			left: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;

		}

		&::before {
			content: '';
			position: absolute;
			bottom: 120px;
			right: 0;
			width: 34%;
			height: 2px;
			background-color: #d8c6a3;

		}

		span {
			display: inline-block;
			height: 110px;
			width: 380px;
			font-size: 60px;
			text-align: center;
			color: #a27b2c;
			background: url(../../assets/img/fontbg.png) no-repeat bottom;

		}
	}

	.surveyFont {

		font-size: 26px;
		font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
		font-weight: 400;
		color: #747474;
		line-height: 70px;
		text-indent: 2em;
	}

	.surimgbox {
		width: 784px;
		margin: 120px auto 720px;

		img {
			width: 100%;
			height: auto;
		}
	}

	.verseandp {
		border-bottom: 1px solid #E1E1E1;
		padding-bottom: 70px;
		padding-top: 80px;
	}

	.puername {
		text-align: center;
		
		img {
			width: 300px;
			height: 414px;
		}

		h3 {
			text-align: center;
			padding: 20px 0;
			height: 33px;
			font-size: 26px;
			font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
			font-weight: 400;
			color: #989898;
			line-height: 36px;
		}

		h4 {
			
			text-align: center;
			font-size: 18px;
			font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
			font-weight: 400;
			color: #000000;
			text-align: justify;
		}
	}

	.versebox {
		display: flex;
		justify-content: flex-start;
		// align-items: center;
		margin-top: -120px;
		position: relative;
		z-index: 0;

		&::after {
			content: '';
			position: absolute;
			top: -50px;
			left: -50px;
			width: 195px;
			height: 195px;
			background: #F6E8D9;
			opacity: 1;
			z-index: -1;
			border-radius: 50%;
		}

		h1 {
			width: 26px;
			font-size: 26px;
			font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
			font-weight: 400;
			color: #000000;
			line-height: 46px;
		}

		h2 {
			width: 16px;
			font-size: 16px;
			font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
			font-weight: 400;
			color: #989898;
			line-height: 46px;
			margin-left: 20px;


		}

		.borderRight {
			border-left: 1px dashed #707070;
			padding-left: 32px;
		}

		p {
			width: 34px;
			font-size: 26px;
			font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
			font-weight: 400;
			color: #000000;
			line-height: 52px;
			margin-left: 32px;
		}
	}

	.humbox {
		position: relative;

		.bgimg {

			width: 100%;
			position: absolute;
			bottom: 300px;
			left: 0;
			z-index: -1;
		}
	}
</style>
